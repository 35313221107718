@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@100;300;400;500;700;800;900&display=swap");
* {
  margin: 0;
  padding: 0;
  font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
}

img {
  vertical-align: bottom;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: black;
}

.clear {
  clear: both;
}

.clearfix:after {
  content: " ";
  display: block;
  clear: both;
}

header {
  width: 100%;
  background: white;
  position: fixed;
  z-index: 1000;
  top: 0;
}
header ul {
  clear: both;
  width: 1140px;
  margin: 20px auto;
  overflow: hidden;
}
header ul li {
  float: left;
  margin-right: 60px;
  margin-top: 10px;
}
header ul li img {
  height: 15px;
}
header ul li:first-child {
  margin-top: 0px;
}
header ul li:first-child img {
  height: 39px;
}
header ul li:last-child {
  margin-right: 0px;
}

#top_main {
  padding-top: 76px;
  clear: both;
  overflow: hidden;
  min-width: 1100px;
}
#top_main .left {
  float: left;
  width: 25%;
  height: 700px;
  text-align: center;
}
#top_main .left a {
  width: 100%;
  height: 100%;
  display: block;
  background: url(../img/top_mainimage01_a.png);
  background-size: cover;
  background-position: center center;
}
#top_main .left a:hover {
  background: url(../img/top_mainimage01_o.png);
  background-size: cover;
  background-position: center center;
}
#top_main .left img {
  padding-top: 463px;
}
#top_main .center {
  float: left;
  width: 50%;
  height: 700px;
  overflow: hidden;
  text-align: center;
}
#top_main .center .center01 {
  width: 100%;
  float: left;
  height: 350px;
}
#top_main .center .center01 a {
  width: 100%;
  height: 100%;
  display: block;
  background: url(../img/top_mainimage02_a.png);
  background-size: cover;
  background-position: center center;
}
#top_main .center .center01 img {
  padding-top: 130px;
}
#top_main .center .center01 a:hover {
  background: url(../img/top_mainimage02_o.png);
  background-size: cover;
  background-position: center center;
}
#top_main .center .center02 {
  width: 50%;
  float: left;
  height: 350px;
}
#top_main .center .center02 a {
  width: 100%;
  height: 100%;
  display: block;
  background: url(../img/top_mainimage03_a.png);
  background-size: cover;
  background-position: center center;
}
#top_main .center .center02 a:hover {
  background: url(../img/top_mainimage03_o.png);
  background-size: cover;
  background-position: center center;
}
#top_main .center .center02 img {
  padding-top: 111px;
}
#top_main .center .center03 {
  width: 50%;
  float: left;
  height: 350px;
}
#top_main .center .center03 a {
  width: 100%;
  height: 100%;
  display: block;
  background: url(../img/top_mainimage04_a.png);
  background-size: cover;
  background-position: center center;
}
#top_main .center .center03 a:hover {
  background: url(../img/top_mainimage04_o.png);
  background-size: cover;
  background-position: center center;
}
#top_main .center .center03 img {
  padding-top: 110px;
}
#top_main .right {
  float: left;
  width: 25%;
  height: 700px;
  text-align: center;
}
#top_main .right a {
  width: 100%;
  height: 100%;
  display: block;
  background: url(../img/top_mainimage05_a_02.png);
  /* background: url(../img/top_mainimge05_a_cp.png); */
  background-size: cover;
  background-position: center center;
}
#top_main .right a:hover {
  background: url(../img/top_mainimage05_o_02.png);
  /* background: url(../img/top_mainimge05_o_cp.png); */
  background-size: cover;
  background-position: center center;
}
#top_main .right img {
  padding-top: 463px;
}

#catch {
  text-align: center;
  margin: 60px 0;
}
#catch h2 {
  margin-bottom: 40px;
}
#catch p {
  line-height: 30px;
  letter-spacing: 0.5px;
}

#our_products h2 {
  margin-bottom: 40px;
  text-align: center;
}

#products_link {
  padding-top: 80px;
  margin-top: -80px;
}

#our_products {
  margin-bottom: 60px;
  background: #f7f5f1;
  padding: 60px 0;
}
#our_products .flexslider {
  max-width: 1140px;
  margin: 0 auto;
  background: none;
  border: none;
}
#our_products .flexslider li {
  width: 285px;
  text-align: center;
}
#our_products .flexslider figure {
  margin-bottom: 20px;
  text-align: center;
}
#our_products .flexslider p {
  text-align: center;
}
#our_products .flexslider img {
  width: auto;
  margin: 0 auto;
}
#our_products .flexslider figure img {
  height: 190px;
}
#our_products .flex-control-nav {
  display: none;
}

#recruit h2 {
  margin-bottom: 40px;
  text-align: center;
}
#recruit div {
  width: 970px;
  margin: 0 auto 60px;
}
#recruit div .recL {
  float: left;
  width: 450px;
}
#recruit div .recL img:hover {
  opacity: 0.5;
}
#recruit div .recR {
  float: right;
  width: 450px;
}
#recruit div .recR img {
  padding-bottom: 13px;
}
#recruit dl {
  line-height: 45px;
  border-bottom: dotted 2px #b3b3b3;
  /*background-image: url(../img/top_recruit_icon.png);
    background-repeat: no-repeat;
    background-position: 96% 50%;*/
}
#recruit dl:first-child {
  border-top: dotted 2px #b3b3b3;
  border-bottom: none;
}
#recruit dt {
  float: left;
  width: 120px;
  text-align: center;
  color: #565656;
}
#recruit dd {
  padding: 10px 30px 10px 120px;
  line-height: 25px;
}
#recruit dd a:hover {
  border-bottom: 1px solid #000;
  padding-bottom: 3px;
}

#news {
  margin: 0 auto 60px;
  max-width: 1140px;
}
#news h2 {
  text-align: center;
  margin-bottom: 40px;
}
#news ul {
  clear: both;
  overflow: hidden;
}
#news ul li {
  float: left;
  width: 262px;
  margin: 0 30px 30px 0;
  height: 290px;
}
#news ul li:nth-child(4n) {
  margin-right: 0px;
}
#news ul li h3 {
  font-weight: normal;
  font-size: 16px;
  padding: 3px 0 3px 0px;
  width: 100%;
  margin: 10px 0;
  text-align: center;
}
#news ul li h3.kids {
  background: #dff1fc;
}
#news ul li h3.enjoy-recipe {
  background: #e8f3e3;
}
#news ul li h3.maedaya-recipe {
  background: #fbdfdf;
}
#news ul li h3.staffblog {
  background: #fffde3;
}
#news ul li h3.news {
  background: #f9e4cd;
}
#news ul li h5 {
  font-size: 16px;
  font-weight: normal;
  margin-top: 20px;
  font-size: 16px;
  font-weight: normal;
  margin-top: 20px;
}
#news figure {
  margin: 10px 0 0px;
  text-align: center;
}
#news li figure img {
  width: 100%;
}

#onlineshop {
  padding: 60px 0;
  background: url(../img/haikei.png);
}

#onlineshop_link {
  padding-top: 80px;
  margin-top: -80px;
}

#onlineshop h2 {
  text-align: center;
  margin-bottom: 20px;
}
#onlineshop p {
  text-align: center;
  margin-bottom: 40px;
}
#onlineshop ul {
  clear: both;
  overflow: hidden;
  max-width: 810px;
  margin: 0 auto;
}
#onlineshop ul li {
  float: left;
  text-align: center;
}
#onlineshop ul li.online {
  background: url(../img/top_img13.jpg);
  width: 100%;
  height: 294px;
}
#onlineshop ul li.amazon {
  background: url(../img/top_img14.jpg);
  width: 390px;
  margin-right: 30px;
  margin-top: 30px;
  height: 209px;
}
#onlineshop ul li.baranori {
  background: url(../img/top_img15.jpg);
  width: 390px;
  margin-top: 30px;
  height: 209px;
}
#onlineshop ul li figure:first-child {
  margin-bottom: 20px;
  padding-top: 40px;
}
#onlineshop ul li:first-child figure:first-child {
  margin-bottom: 20px;
  padding-top: 80px;
}
#onlineshop ul li a {
  display: block;
  height: 100%;
}

#gmap {
  width: 100%;
  height: 300px;
}

footer .footer_link {
  clear: both;
  overflow: hidden;
  width: 1140px;
  margin: 40px auto 80px;
}
footer .footer_link .content {
  float: left;
  margin-right: 60px;
}
footer .footer_link .content:last-child {
  margin-right: 0px;
  margin-left: 14px;
}
footer .footer_link p {
  margin: 10px 0px 0px 23px;
  font-size: 13px;
}
footer .footer_content {
  background: url(../img/top_footer.png);
  padding: 20px 0;
  background-size: cover;
}
footer .footer_content .content {
  max-width: 1140px;
  margin: 0 auto;
  clear: both;
  overflow: hidden;
}
footer .footer_content .left {
  float: left;
  color: white;
}
footer .footer_content .left figure {
  margin-bottom: 20px;
}
footer .footer_content .right {
  float: right;
}
footer .footer_content .copy {
  text-align: center;
  color: white;
}
footer .footer_content a {
  color: white;
}

#page-top {
  position: fixed;
  bottom: 10px;
  right: 10px;
  font-size: 77%;
  z-index: 1000000000000000;
}
#page-top a {
  text-decoration: none;
  color: #fff;
  width: 60px;
  padding: 0px 0;
  text-align: center;
  display: block;
}
#page-top img {
  width: 60px;
}

#page_main {
  padding-top: 76px;
  clear: both;
  overflow: hidden;
}
#page_main .left {
  background: #bc1e2d;
  width: 40%;
  text-align: center;
  float: left;
}
#page_main .left img {
  padding: 40px 0;
}
#page_main .right {
  width: 60%;
  height: 184px;
  float: right;
}
#page_main.jikihan .right {
  background: url(../img/jikihan_img01.jpg);
  background-size: cover;
}
#page_main.project .right {
  background: url(../img/project_img01.jpg);
  background-size: cover;
}
#page_main.blog .right {
  background: url(../img/blog_img01.png);
  background-size: cover;
}
#page_main.company .right {
  background: url(../img/about_img01.jpg);
  background-size: cover;
}
#page_main.company .left img {
  padding: 49px 0 50px;
}
#page_main.recruit .right {
  background: url(../img/saiyou_img02_2.jpg);
  background-size: cover;
}
#page_main.lineup .right {
  background: url(../img/lineup_img01.jpg);
  background-size: cover;
}

.product-heroheader {
  margin-bottom: 40px;
}
.product-heroheader img {
  max-width: 100%;
  height: auto;
}

.product-title span {
  display: block;
  margin: 20px 0 5px 0;
}

#page_main.makanai .left img {
  padding: 41px 0 42px;
}
#page_main.makanai .right {
  background: url(../img/makanai_2.png);
  background-size: cover;
}
#page_main.contact .right {
  background: url(../img/contact_img01.png);
  background-size: cover;
}
#page_main.contact .left img {
  padding: 41px 0 42px;
}
#page_main.privacy .right {
  background: url(../img/privacy_img01.png);
  background-size: cover;
}
#page_main.privacy .left img {
  padding: 41px 0 42px;
}
#page_main.blog .left img {
  padding: 41px 0 42px;
}

#pankuzu {
  max-width: 1000px;
  margin: 20px auto 40px;
}

#page_content {
  max-width: 1000px;
  margin: 0 auto 100px;
  clear: both;
  overflow: hidden;
}
#page_content #nav_side {
  width: 250px;
  float: left;
}
#page_content #nav_side ul {
  margin-bottom: 45px;
}
#page_content #nav_side li {
  padding: 15px 0px 15px 10px;
  background: white;
  border-bottom: 1px solid #c8c8c8;
  width: 100%;
  box-sizing: border-box;
}
#page_content #nav_side li a {
  display: block;
  text-indent: -1em;
  margin-left: 1em;
}
#page_content #nav_side li.check {
  background: #fff4f4;
}
#page_content #nav_side li span img {
  padding: 0 5px 5px 0;
}
#page_content #nav_side h2 {
  margin: 40px 0 0;
  font-size: 16px;
  padding: 10px 0px 10px 20px;
  font-weight: normal;
  background: #ffd5d6;
  width: 100%;
  border-bottom: 1px solid silver;
  box-sizing: border-box;
}
#page_content #nav_side h2:first-child {
  margin: 0;
}
#page_content #nav_side ul.archive li:before {
  content: url(../img/jikihan_icon01.png);
}
#page_content #nav_side ul.archive li a {
  display: inline;
  padding-left: 5px;
}
#page_content #nav_side ul .subtitle {
  text-align: center;
  border-bottom: none;
  padding: 10px 0px;
}
#page_content #nav_side .hs .subtitle {
  background-color: #e2f0db;
}
#page_content #nav_side .un .subtitle {
  background-color: #d7ecfa;
}
#page_content #nav_side .company .subtitle {
  background-color: #ffd5d6;
}
#page_content #nav_side .mid .subtitle {
  background-color: #e5eec0;
}
#page_content #nav_side .part .subtitle {
  background-color: #ffb5c2;
}
#page_content #nav_side .hs li.check {
  background: #f2f8ef;
}
#page_content #nav_side .un li.check {
  background: #eef7fd;
}
#page_content #nav_side .company li.check {
  background: #fff4f4;
}
#page_content #nav_side .mid li.check {
  background-color: #f6f9e9;
}
#page_content #nav_side .part li.check {
  background-color: #ffeff2;
}
#page_content #content {
  width: 700px;
  float: right;
  margin-bottom: 40px;
}
#page_content h2.title {
  padding: 10px 20px 3px 0;
  font-weight: normal;
  border-bottom: 1px solid black;
  display: inline-block;
  margin-bottom: 40px;
  font-family: serif;
  /*letter-spacing: 2px;*/
  font-size: 24px;
}
#page_content h2.title.top {
  padding: 0 20px 3px 0;
  font-weight: normal;
  border-bottom: 1px solid black;
  display: inline-block;
  margin-bottom: 40px;
  font-family: serif;
  font-size: 24px;
  /*letter-spacing: 2px;*/
}
#page_content h2.un_entry.title.top {
  display: block;
}

.un_entry_comment_inner {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  margin: 30px 0;
  line-height: 2em;
}
.un_entry_comment_inner .un_entry_comment_photo {
  margin-right: 20px;
}
.un_entry_comment_inner b {
  font-size: 21px;
  font-weight: bold;
}

.entry-uservoice {
  display: block;
  background-color: #e7e1d5;
  padding: 30px;
  margin: 30px 0;
}
.entry-uservoice h2 {
  font-size: 18px;
  margin-bottom: 20px;
  font-family: serif;
}

@media (max-width: 670px) {
  .entry-uservoice {
    padding: 20px;
    margin: 30px -20px;
    text-align: center;
  }
  .entry-uservoice h2 {
    margin-bottom: 10px;
  }
  .entry-uservoice img {
    max-width: 100%;
    height: auto;
  }
}
#page_content h3.subtitle {
  margin-bottom: 40px;
  font-weight: normal;
}
#page_content h3.subtitle:before {
  content: "";
}
#page_content #content p {
  line-height: 26px;
  letter-spacing: 1px;
}
#page_content #content .tsuhan figure,
#page_content #content .senetu figure {
  padding: 40px 0 0;
}
#page_content #content h5.contact {
  font-size: 14px;
  border: 1px solid silver;
  padding: 15px 0;
  width: 100%;
  margin: 60px auto 0;
  text-align: center;
  font-weight: normal;
  box-sizing: border-box;
  letter-spacing: 1px;
}
#page_content #content h5.contact a {
  font-family: serif;
  display: block;
}
#page_content #content .tsuhan,
#page_content #content .senetu {
  padding-top: 80px;
  margin-top: -80px;
}
#page_content .company_gaiyou table {
  margin: 0px 0 60px;
  border-bottom: 1px solid silver;
  border-collapse: collapse;
  width: 100%;
}
#page_content .company_gaiyou table th {
  padding: 20px;
  width: 200px;
  text-align: left;
  border-top: 1px solid silver;
  font-weight: normal;
}
#page_content .company_gaiyou table td {
  padding: 20px;
  text-align: left;
  border-top: 1px solid silver;
  /*padding-left: 60px;*/
}
#page_content .company_access iframe {
  margin: 40px 0 0;
}
#page_content .think img {
  padding: 40px 0 0;
}
#page_content .think figure.sub img:first-child {
  padding-right: 40px;
}
#page_content .think h5 {
  text-align: left;
  font-weight: normal;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 100px;
}
#page_content #content .think .smt a img {
  width: 100%;
}
#page_content .history table {
  margin: 0px 0 60px;
  border-bottom: 1px solid silver;
  border-collapse: collapse;
  width: 100%;
}
#page_content .history table th {
  padding: 20px 0;
  text-align: left;
  border-top: 1px solid silver;
  font-weight: normal;
}
#page_content .history table td {
  padding: 10px 0;
  text-align: left;
  border-top: 1px solid silver;
  padding-left: 60px;
}
#page_content .history iframe {
  margin: 40px 0 0;
}
#page_content .history a {
  color: navy;
  text-decoration: underline;
}
#page_content .shokuhin figure {
  margin-top: 40px;
}
#page_content .shokuhin figure img {
  height: 200px;
  padding: 20px;
  border: 1px solid silver;
}
#page_content .shokuhin figure img:first-child {
  margin-right: 20px;
}
#page_content .eisei figure {
  margin: 40px 0 0 40px;
}
#page_content .eisei figure img {
  padding-right: 20px;
}
#page_content .person figure {
  margin: 40px 0 0px;
}
#page_content .makanai figure {
  margin: 40px 0 40px;
}
#page_content .makanai figure.last {
  margin: 40px 0 0px;
}
#page_content .makanai h5 {
  font-size: 14px;
  border: 1px solid silver;
  padding: 15px 0;
  width: 100%;
  margin: 60px auto 0;
  text-align: center;
  font-weight: normal;
  box-sizing: border-box;
  letter-spacing: 1px;
}
#page_content .makanai h5 a {
  font-size: 14px;
  font-family: serif;
  display: block;
}
#page_content .makanai .product_main {
  clear: both;
  overflow: hidden;
  margin-top: 60px;
}
#page_content .makanai .product_main .image {
  float: left;
  margin-right: 40px;
}
#page_content .makanai .product_main .detail {
  float: left;
}
#page_content .makanai .product_main .detail h2 {
  margin: 0 0 20px 0;
}
#page_content .makanai .product_main .detail h5 {
  margin: 0 0 40px 0;
}
#page_content .makanai .product_main .detail h3 {
  text-align: center;
  color: white;
  background: #565656;
  font-weight: normal;
  padding: 10px 60px;
  font-size: 16px;
}
#page_content .makanai .product_main .detail table {
  margin-top: 40px;
  border-collapse: collapse;
  width: 100%;
}
#page_content .makanai .product_main .detail table th {
  padding: 10px 30px 10px 10px;
  background: #eeeeee;
  border: 1px solid silver;
  text-align: left;
  font-weight: normal;
}
#page_content .makanai .product_main .detail table td {
  padding: 10px 0px 10px 10px;
  border: 1px solid silver;
}
#page_content .product .product_main .detail table.eiyo {
  margin: 20px 0 0 0;
}

.product_main p.atten {
  text-align: right;
  font-size: 14px;
}

#page_content .product .product_main .detail table.eiyo td {
  width: 50%;
}
#page_content .makanai .radio {
  display: table;
  margin: 50px 0;
  border-collapse: separate;
  border-spacing: 0 20px;
}
#page_content .makanai .radio .comment,
#page_content .makanai .radio figure {
  display: table-cell;
  vertical-align: middle;
}
#page_content .makanai .radio .comment {
  padding-right: 30px;
}
#page_content .makanai .radio .comment p {
  letter-spacing: 0 !important;
}
#page_content .makanai .radio .comment h3 {
  border-top: 2px solid #ad0922;
  border-bottom: 2px solid #ad0922;
  text-align: center;
  padding: 5px 0;
  margin-bottom: 10px;
}

/*まかない アワード*/
.baranori-award h2 {
  margin-bottom: 20px;
}
.baranori-award h3 {
  font-size: 23px;
  font-weight: bold;
  font-family: "ヒラギノ丸ゴ Pro W4", "ヒラギノ丸ゴ Pro", "Hiragino Maru Gothic Pro", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "HG丸ｺﾞｼｯｸM-PRO", "HGMaruGothicMPRO";
}
.baranori-award h3 span {
  color: #e91c24;
  font-family: "ヒラギノ丸ゴ Pro W4", "ヒラギノ丸ゴ Pro", "Hiragino Maru Gothic Pro", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "HG丸ｺﾞｼｯｸM-PRO", "HGMaruGothicMPRO";
}

#page_content .product .baranori-award figure {
  margin: 20px 0;
}
#page_content .product .baranori-award figure img {
  max-width: 573px;
  height: auto;
}

.baranori-award p.comment {
  font-size: 14px;
  margin-bottom: 60px;
}
.baranori-award p.comment01 {
  font-size: 14px;
  margin-bottom: 60px;
  padding: 30px 30px;
  border: solid 1px #231f20;
  margin: 30px auto 40px;
}
.baranori-award p.comment01 a {
  border-bottom: solid 1px #231f20;
  padding-bottom: 2px;
}
.baranori-award .japan-food img {
  margin-bottom: 40px;
}

#page_content .product .baranori-award .award-link {
  margin-top: 60px;
}
#page_content .product .baranori-award .award-link figure img {
  max-width: 360px;
  height: auto;
}

.award-link a {
  display: block;
  max-width: 360px;
  background-color: #bb1e2d;
  color: #fff;
  height: 47px;
  line-height: 47px;
  font-size: 15px;
  text-align: center;
}

#page_content .career .career_scroll ul {
  clear: both;
  overflow: hidden;
  margin: 60px 0 -40px;
  padding-bottom: 40px;
  border-bottom: 1px dashed silver;
}
#page_content .career .career_scroll li {
  float: left;
  margin-right: 16px;
}
#page_content .career .career_scroll li:last-child {
  margin-right: 0px;
}
#page_content .career .career_scroll li a {
  display: block;
  width: 222px;
  height: 99.5px;
}
#page_content .career .career_scroll li:nth-child(1) a {
  background: url(../img/un-career_btn01.png);
}
#page_content .career .career_scroll li:nth-child(2) a {
  background: url(../img/un-career_btn02.png);
}
#page_content .career .career_scroll li:nth-child(3) a {
  background: url(../img/un-career_btn03.png);
}
#page_content .career .career_scroll li a:hover {
  background-position: center bottom;
}
#page_content .career h3 {
  margin: 40px 0 20px;
}
#page_content .career h3.top {
  margin: 0px 0 20px;
}
#page_content .career h4.marker {
  background: linear-gradient(transparent 0%, #ffff66 0%);
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
}
#page_content .career .career_skill01 h4 {
  text-align: center;
  background: #dff1fc;
  font-size: 16px;
  font-weight: normal;
  padding: 10px 0;
}
#page_content .career .career_skill01 table {
  border-collapse: separate;
  border-spacing: 0 20px;
  margin-bottom: 40px;
}
#page_content .career .career_skill01 table th {
  background: #dff1fc;
  font-weight: normal;
  padding: 20px;
  text-align: left;
  border: 3px solid #dff1fc;
  width: 150px;
}
#page_content .career .career_skill01 table td {
  font-weight: normal;
  padding: 20px;
  text-align: left;
  border: 3px solid #dff1fc;
}
#page_content .career .career_skill02 h4 {
  text-align: center;
  background: #e8f3e3;
  font-size: 16px;
  font-weight: normal;
  padding: 10px 0;
}
#page_content .career .career_skill02 table {
  border-collapse: separate;
  border-spacing: 0 20px;
  margin-bottom: 40px;
  width: 100%;
}
#page_content .career .career_skill02 table tr {
  margin-bottom: 20px;
}
#page_content .career .career_skill02 table th {
  background: #e8f3e3;
  font-weight: normal;
  padding: 20px;
  text-align: left;
  border: 3px solid #e8f3e3;
  width: 150px;
  margin-bottom: 20px;
}
#page_content .career .career_skill02 table td {
  font-weight: normal;
  padding: 20px;
  text-align: left;
  border: 3px solid #e8f3e3;
  margin-bottom: 20px;
}
#page_content .career .career_skill03 {
  border-bottom: 1px dashed silver;
}
#page_content .career .career_skill03 h4 {
  text-align: center;
  background: #fbdfdf;
  font-size: 16px;
  font-weight: normal;
  padding: 10px 0;
}
#page_content .career .flast,
#page_content .career .last {
  border-bottom: none;
}
#page_content .career .career_skill03 table {
  border-collapse: separate;
  border-spacing: 0 20px;
  margin-bottom: 40px;
}
#page_content .career .career_skill03 table tr {
  margin-bottom: 20px;
}
#page_content .career .career_skill03 table th {
  background: #fbdfdf;
  font-weight: normal;
  padding: 20px;
  text-align: left;
  border: 3px solid #fbdfdf;
  width: 150px;
  margin-bottom: 20px;
}
#page_content .career .career_skill03 table td {
  font-weight: normal;
  padding: 20px;
  text-align: left;
  border: 3px solid #fbdfdf;
  margin-bottom: 20px;
}
#page_content h2#somu.title {
  padding-top: 100px;
}
#page_content h2#seizo.title, #page_content h2#eigyo.title, #page_content h2#seisan.title {
  padding-top: 100px;
  margin-top: -40px;
}
#page_content #content .career .teach {
  border: solid 1px #f2a509;
  padding: 30px 35px;
  margin: 50px 0;
  border-radius: 10px;
}
#page_content #content .career .teach figure {
  width: 100%;
  border-bottom: solid 1px #f2a509;
  padding: 20px 0 15px;
  margin-bottom: 15px;
}
#page_content .requirements table {
  margin: 0px 0 60px;
  border-bottom: 1px solid silver;
  border-collapse: collapse;
  width: 100%;
}
#page_content .requirements table a {
  color: blue;
}
#page_content .requirements table th {
  padding: 20px;
  width: 200px;
  text-align: left;
  border-top: 1px solid silver;
  font-weight: normal;
}
#page_content .requirements table td {
  padding: 20px;
  text-align: left;
  border-top: 1px solid silver;
}
#page_content .requirements table tr:first-child th,
#page_content .requirements table tr:first-child td {
  border-top: none;
}
#page_content .requirements p {
  margin-bottom: 40px;
}
#page_content .contact table {
  margin: 0px 0 60px;
  border-bottom: 1px solid silver;
  border-collapse: collapse;
  width: 100%;
}
#page_content .contact table th {
  padding: 20px;
  text-align: left;
  font-weight: normal;
  background: #ffebd6;
  border: 1px solid silver;
  font-size: 16px;
}
#page_content .contact table td {
  padding: 20px;
  text-align: left;
  border: 1px solid silver;
  font-size: 16px;
}
#page_content .contact input {
  width: 90% !important;
}
#page_content .contact textarea {
  height: 300px !important;
  width: 90% !important;
  border: 1px solid silver !important;
}
#page_content .contact .mfp_buttons button {
  margin: 0px auto;
  display: inline-block;
  width: 200px !important;
  padding: 20px 0px;
  background: #ffebd6;
  border: 1px solid silver;
  font-size: 14px;
  letter-spacing: 1px;
}
#page_content .mfp_buttons .wpcf7-confirm,
#page_content .mfp_buttons .wpcf7-submit {
  margin: 0px auto;
  display: inline-block;
  width: 200px !important;
  padding: 20px 0px;
  background: #ffebd6;
  border: 1px solid silver;
  font-size: 14px;
  letter-spacing: 1px;
}
#page_content .mfp_buttons .wpcf7-back {
  margin: 0px auto;
  display: inline-block;
  width: 200px !important;
  padding: 20px 0px;
  background: #999999;
  color: #fff;
  border: 1px solid #999999;
  font-size: 14px;
  letter-spacing: 1px;
  margin-right: 40px;
}

.shokushu .wpcf7-list-item {
  display: block;
}

@media screen and (max-width: 768px) {
  #page_content .mfp_buttons .wpcf7-submit {
    margin: 0px auto;
    display: inline-block;
    width: 45% !important;
    padding: 20px 0px;
    background: #ffebd6;
    border: 1px solid silver;
    font-size: 14px;
    letter-spacing: 1px;
  }
  #page_content .mfp_buttons .wpcf7-back {
    margin: 0px auto;
    display: inline-block;
    width: 45% !important;
    padding: 20px 0px;
    background: #999999;
    color: #fff;
    border: 1px solid #999999;
    font-size: 14px;
    letter-spacing: 1px;
    margin-right: 5%;
  }
  #page_content .wpcf7-form .mfp_buttons {
    margin-bottom: 60px;
  }
}
.wpcf7-form input[type=text],
.wpcf7-forminput[type=email] {
  padding: 5px 15px;
}

.wpcf7-form input[type=tel],
.wpcf7-form textarea {
  padding: 5px 15px;
}

.wpcf7-select {
  text-transform: none;
  padding: 5px 10px;
  vertical-align: middle;
  font-size: 16px;
  padding: 5px 10px;
  -webkit-appearance: button;
  border: 1px solid silver;
  background: none;
}

.wpcf7-spinner {
  display: none;
}

.custom-wpcf7c-confirmed input {
  background-color: #999999;
  color: black;
  border: 1px solid #333333;
}

#page_content .contact .privacy {
  width: 100%;
  border: 1px solid silver;
  box-sizing: border-box;
  padding: 5%;
  height: 300px;
  overflow: auto;
}
#page_content .contact .privacy h3 {
  margin-bottom: 20px;
  border-bottom: 1px solid black;
  display: inline-block;
}
#page_content .contact .doui {
  text-align: center;
  margin: 20px auto 40px;
}
#page_content .contact .doui input {
  width: auto !important;
  margin: 0 5px 2px 0;
  display: inline-block;
}
#page_content .contact .doui div.mfp_err {
  width: 228px;
  margin: 0 auto;
}

ul#mfp_phase_stat {
  display: none;
}

div#mfp_phase_confirm h4 {
  font-size: 16px !important;
  padding: 10px 0px 0px;
  text-align: left !important;
  font-weight: normal;
  margin-bottom: 40px;
}
div.mfp_buttons button#mfp_button_cancel {
  background: #e8e8e8 !important;
}

#page_content .privacy {
  margin-bottom: 60px;
}
#page_content .privacy figure {
  margin: 40px 0;
}

img.mailaddress {
  padding-top: 5px;
}

#page_content .entry table {
  margin: 40px 0 60px;
  border-bottom: 1px solid silver;
  border-collapse: collapse;
  width: 100%;
}
#page_content .entry table th {
  padding: 20px;
  text-align: left;
  font-weight: normal;
  background: rgb(247, 245, 241) none repeat scroll 0% 0%;
  border: 1px solid silver;
  font-size: 16px;
}
#page_content .entry table td {
  padding: 20px;
  text-align: left;
  border: 1px solid silver;
  font-size: 16px;
}
#page_content .entry input {
  width: 90% !important;
}
#page_content .entry input[type=checkbox], #page_content .entry input[type=radio] {
  width: auto !important;
}
#page_content .entry .job label {
  display: block;
}
#page_content .entry textarea {
  height: 300px !important;
  width: 90% !important;
  border: 1px solid silver !important;
}
#page_content .entry .mfp_buttons button {
  margin: 0px auto;
  display: inline-block;
  width: 200px;
  padding: 20px 0px;
  background: rgb(247, 245, 241) none repeat scroll 0% 0%;
  border: 1px solid silver;
  font-size: 14px;
  letter-spacing: 1px;
}

span.attention {
  color: red;
}

#page_content .entry span.attention,
#page_content .contact span.attention {
  color: red;
}
#page_content .entry .privacy {
  width: 100%;
  border: 1px solid silver;
  box-sizing: border-box;
  padding: 5%;
  height: 300px;
  overflow: auto;
}
#page_content .entry .privacy h3 {
  margin-bottom: 20px;
  border-bottom: 1px solid black;
  display: inline-block;
}
#page_content .entry .doui {
  text-align: center;
  margin: 20px auto 40px;
}
#page_content .entry .doui input {
  width: auto !important;
  margin: 0 5px 2px 0;
  display: inline-block;
}
#page_content .thanks h1 {
  font-weight: normal;
  padding: 0 0 5px 0;
  border-bottom: 1px solid black;
  width: 80%;
  font-size: 20px;
  text-align: center;
  margin: 0 auto 40px;
}
#page_content .thanks p {
  text-align: center;
}
#page_content .recruit_button ul,
#page_content .recruit_button_un ul {
  clear: both;
  overflow: hidden;
  margin-bottom: 40px;
}
#page_content .recruit_button ul li a,
#page_content .recruit_button_un ul li a {
  margin-right: 30px;
  float: left;
}
#page_content .recruit_button ul li:first-child a {
  background: url(../img/aboutwork_icon01.png);
  background-size: cover;
  width: 133px;
  height: 133px;
}
#page_content .recruit_button ul li:nth-child(2) a {
  background: url(../img/aboutwork_icon02.png);
  background-size: cover;
  width: 133px;
  height: 132.5px;
}
#page_content .recruit_button ul li:nth-child(3) a {
  background: url(../img/aboutwork_icon03.png);
  background-size: cover;
  width: 133px;
  height: 132.5px;
}
#page_content .recruit_button ul li:nth-child(4) a {
  background: url(../img/aboutwork_icon04.png);
  background-size: cover;
  width: 133px;
  height: 132.5px;
}
#page_content .recruit_button_un ul li:first-child a {
  background: url(../img/aboutwork_icon01.png);
  background-size: cover;
  width: 226px;
  height: 133px;
}
#page_content .recruit_button_un ul li:nth-child(2) a {
  background: url(../img/aboutwork_icon04.png);
  background-size: cover;
  width: 133px;
  height: 132.5px;
}
#page_content .recruit_button ul li a:hover,
#page_content .recruit_button_un ul li a:hover {
  background-position: center bottom;
  background-size: cover;
}
#page_content .recruit figure {
  margin: 40px 0;
}
#page_content .recruit_content {
  margin: -80px 0 0;
  padding-bottom: 60px;
  border-bottom: 1px solid silver;
  padding-top: 140px;
}
#page_content .recruit_content.top {
  margin: -140px 0 0;
  padding-bottom: 60px;
  border-bottom: 1px solid silver;
}
#page_content .recruit_content.last {
  border-bottom: none;
}
#page_content .recruit_content h3 {
  margin-bottom: 40px;
}
#page_content h5.entry a {
  display: block;
  text-indent: -9999px;
  background: url("../img/entry_pc.png") left top no-repeat;
  width: 840px;
  height: 55px;
  margin: 60px auto;
}
#page_content h5.entry a:hover {
  background-position: left bottom;
}
#page_content #content .hs_menu ul li,
#page_content #content .un_menu ul li,
#page_content #content .mid_menu ul li,
#page_content #content .part_menu ul li {
  float: left;
  margin-right: 15px;
}
#page_content #content .hs_menu ul li img:hover,
#page_content #content .un_menu ul li img:hover,
#page_content #content .mid_menu ul li img:hover,
#page_content #content .part_menu ul li img:hover {
  opacity: 0.5;
}
#page_content #content .hs_menu .hs_menutitle {
  display: block;
  text-indent: -9999px;
  background: url("../img/hs-aboutwork_img07.png") no-repeat;
  margin-bottom: 20px;
}
#page_content #content .un_menu .un_menutitle {
  display: block;
  text-indent: -9999px;
  background: url("../img/un-aboutwork_img05.png") no-repeat;
  margin-bottom: 20px;
}
#page_content #content .mid_menu .mid_menutitle {
  display: block;
  text-indent: -9999px;
  background: url("../img/mid-aboutwork_img.png") no-repeat;
  margin-bottom: 20px;
  width: 125px;
  height: 21px;
  -webkit-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
}
#page_content #content .part_menu .part_menutitle {
  display: block;
  text-indent: -9999px;
  background: url("../img/part-link-title.png") no-repeat;
  margin-bottom: 20px;
  width: 125px;
  height: 21px;
  -webkit-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
}
#page_content #content .hs_title,
#page_content #content .un_title {
  margin-bottom: 30px;
}
#page_content #content .hs_title img,
#page_content #content .un_title img {
  width: 100%;
}
#page_content #content .recruit h2 {
  margin-bottom: 30px;
}

.un_maintitle {
  padding-bottom: 10px;
  margin-bottom: 20px;
  font-family: serif;
  font-size: 24px;
  font-weight: normal;
  border-bottom: 1px solid #000;
}

.un_title--anchor a {
  color: #be1e2d;
  border: 2px solid #be1e2d;
  padding: 10px 50px;
  font-size: 18px;
  transition: 0.3s;
  display: block;
  text-align: center;
}
.un_title--anchor a:hover {
  color: #fff;
  background-color: #be1e2d;
  border: 2px solid #fff;
}

#page_content div#recruit01 {
  padding-top: 100px;
  margin-top: -60px;
  margin-bottom: 60px;
}
#page_content div#recruit02, #page_content div#recruit03, #page_content div#recruit04 {
  padding-top: 100px;
  margin-top: -60px;
}
#page_content .product .product_main {
  clear: both;
  overflow: hidden;
}
#page_content .product .product_main .image {
  float: left;
  margin-right: 40px;
  max-width: 430px;
}
#page_content .product .product_main .image img {
  max-width: 100%;
}
#page_content .product .product_main .detail {
  float: left;
  max-width: 370px;
}
#page_content .product .product_main .detail h2 {
  margin: 0 0 20px 0;
}
#page_content .product .product_main .detail h5 {
  margin: 20px 0 0px 0;
  font-weight: normal;
}
#page_content .product .product_main .detail h3 a,
#page_content .product .product_main .detail .red h3 a {
  text-align: center;
  color: white;
  background: #bc1e2d;
  border-bottom: solid 3px #750617;
  font-weight: normal;
  padding: 15px 40px;
  font-size: 16px;
  display: block;
}
#page_content .product .product_main .detail h3 a:hover,
#page_content .product .product_main .detail .red h3 a:hover {
  border-bottom: solid 0px #750617;
  border-top: solid 3px #fff;
}
#page_content .product .product_main .detail .blue {
  margin-top: 20px;
}
#page_content .product .product_main .detail .blue h3 a {
  text-align: center;
  color: white;
  background: #3a86c9;
  border-bottom: solid 3px #063480;
  font-weight: normal;
  padding: 15px 40px;
  font-size: 16px;
  display: block;
}
#page_content .product .product_main .detail .blue h3 a:hover {
  border-bottom: solid 0px #063480;
  border-top: solid 3px #fff;
}
#page_content .product .product_main .detail table {
  margin-top: 40px;
  border-collapse: collapse;
  width: 100%;
}
#page_content .product .product_main .detail table th {
  padding: 10px 10px 10px 10px;
  background: #eeeeee;
  border: 1px solid silver;
  text-align: left;
  font-weight: normal;
  width: 90px;
}
#page_content .product .product_main .detail table td {
  padding: 10px 10px 10px 10px;
  border: 1px solid silver;
}
#page_content .product h2 {
  margin-top: 60px;
}
#page_content .product figure {
  margin-top: 40px;
}
#page_content .product figure img {
  max-width: 100%;
}
#page_content .project figure.pict {
  margin: 40px 0;
}
#page_content .project ul {
  clear: both;
  overflow: hidden;
}
#page_content .project ul li {
  float: left;
  width: 260px;
  margin: 0 30px 30px 0;
}
#page_content .project ul li:nth-child(3n) {
  margin-right: 0px;
}
#page_content .project ul li h3 {
  font-weight: normal;
  font-size: 16px;
  padding: 3px 0 3px 0px;
  width: 100%;
  margin: 10px 0;
  text-align: center;
}
#page_content .project ul li h3.kids {
  background: #dff1fc;
}
#page_content .project ul li h3.enjoy-recipe {
  background: #e8f3e3;
}
#page_content .project ul li h3.maedaya-recipe {
  background: #fbdfdf;
}
#page_content .project ul li h3.staffblog {
  background: #fffde3;
}
#page_content .project ul li h3.news {
  background: #f9e4cd;
}
#page_content .project ul li h5 {
  font-size: 16px;
  font-weight: normal;
  margin-top: 20px;
  font-size: 16px;
  font-weight: normal;
  margin-top: 20px;
}
#page_content .project figure {
  margin: 10px 0 0px;
  text-align: center;
}
#page_content .project figure img {
  width: 100%;
}
#page_content #content .event div.eventTitle {
  background: #fff4f4;
  margin-bottom: 50px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-around;
  -ms-align-items: center;
  align-items: center;
}
#page_content #content .event figure.eventImg {
  margin: 0;
  padding: 15px;
}
#page_content #content .event div h2 {
  font-size: 20px;
  margin: 20px 0;
}
#page_content #content .event p.date {
  font-size: 16px;
  color: #58595b;
}
#page_content .event {
  margin-bottom: 60px;
}
#page_content .event figure {
  margin: 40px 0;
}
#page_content .event div figure a img:hover {
  opacity: 0.5;
}

#saiyouTop h2 {
  margin-bottom: 40px;
  text-align: center;
}
#saiyouTop .pc {
  text-align: center;
}
#saiyouTop .saiyouContents {
  width: 100%;
  background-color: #fff5dc;
  margin: 50px 0;
  padding: 50px 0;
}
#saiyouTop .saiyouTable {
  width: 700px;
  margin: 0 auto;
}
@media screen and (max-width: 640px) {
  #saiyouTop .saiyouTable {
    width: 100%;
  }
}
#saiyouTop figure {
  text-align: center;
  margin-bottom: 10px;
}
#saiyouTop .saiyouTable ul {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px 0;
  justify-content: center;
  text-align: center;
}
@media screen and (max-width: 640px) {
  #saiyouTop .saiyouTable ul {
    grid-template-columns: auto;
    gap: 15px;
  }
}
#saiyouTop .saiyouInfo {
  margin-bottom: 70px;
}
#saiyouTop div .rec_menu {
  width: 880px;
  margin: 0 auto 50px;
}
#saiyouTop div #parttime {
  margin-top: -100px;
  padding-top: 100px;
}
#saiyouTop div .rec_menu p {
  margin-left: 20px;
  margin-bottom: 10px;
}
#saiyouTop div .rec_menuL {
  width: 420px;
  float: left;
}
#saiyouTop div .rec_menuL .vimeo {
  margin-bottom: 6px;
  height: 250px;
}
@media screen and (max-width: 640px) {
  #saiyouTop div .rec_menuL .vimeo .vimeo {
    position: relative;
  }
  #saiyouTop div .rec_menuL .vimeo .vimeo iframe {
    width: 100%;
  }
}
#saiyouTop div .rec_menuR {
  width: 420px;
  float: right;
}
#saiyouTop div .rec_menuR .photo {
  margin-bottom: 13px;
}
#saiyouTop div .rec_menuR .photo img {
  /* margin-top: 7px; */
}

.link-rashinban {
  text-align: center;
  margin-top: 20px;
}

#saiyouTop div .rec_menuC {
  text-align: center;
}
#saiyouTop div .rec_menuL li {
  border: 2px solid #e6f1e1;
  font-size: 16px;
  line-height: 50px;
  margin-bottom: 5px;
  position: relative;
}
#saiyouTop div .rec_menuR li {
  border: 2px solid #d7ecfa;
  font-size: 16px;
  line-height: 50px;
  margin-bottom: 5px;
  position: relative;
}
#saiyouTop div .rec_menuL li a,
#saiyouTop div .rec_menuR li a {
  display: block;
  /*width: 378px;*/
  padding-left: 40px;
  line-height: 1.4;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 15px;
}
#saiyouTop div .rec_menuL li span,
#saiyouTop div .rec_menuR li span {
  position: absolute;
  right: 40px;
}
#saiyouTop div .rec_menuL li a:hover {
  background: #e6f1e1;
}
#saiyouTop div .rec_menuR li a:hover {
  background: #d7ecfa;
}
#saiyouTop .maedaya {
  width: 880px;
  margin: 0 auto 70px;
  padding-bottom: 100px;
}
#saiyouTop .maedaya li {
  float: left;
  margin-right: 26px;
}
#saiyouTop .maedaya li:last-child {
  margin-right: 0;
}
#saiyouTop .maedaya li img:hover {
  opacity: 0.5;
}
#saiyouTop .top-part-inner {
  max-width: 420px;
  margin: 0 auto 75px auto;
}
#saiyouTop .top-part-inner h2 {
  /*font-size: 35px;*/
  /*font-weight: normal;*/
}
#saiyouTop .top-part-inner figure {
  margin: 10px 0;
}
#saiyouTop #part_menu ul li {
  border: 2px solid #ffa4b6;
  font-size: 16px;
  line-height: 50px;
  margin-bottom: 5px;
  position: relative;
}
#saiyouTop #part_menu ul li a {
  display: block;
  line-height: 1.4;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 15px;
}
#saiyouTop #part_menu ul li a:hover {
  background-color: #ffa4b6;
}
#saiyouTop #part_menu ul li span {
  position: absolute;
  right: 40px;
  /* top: 17px; */
}

.part-line {
  margin-bottom: 65px;
}
.part-line figure {
  margin: 35px 0 25px 0;
}
.part-line h3 {
  font-weight: normal;
  font-size: 20px;
}

.part-work,
.part-bosyu,
.part-entry,
.part-howto {
  margin: 60px 0;
}

.part-work h2,
.part-bosyu h2,
.part-entry h2,
.part-howto h2 {
  border-bottom: 1px solid #000;
  padding-bottom: 10px;
  margin-bottom: 40px;
  font-family: serif;
  font-size: 24px;
  font-weight: normal;
}

.part-work p.comment {
  margin: 30px 0 0 0;
}

#part-bosyu {
  padding-top: 150px;
  margin-top: -150px;
}

.part-work-video {
  position: relative;
  width: 560px;
  height: auto;
}

.part-bosyu th {
  min-width: 80px;
}

.line-link {
  margin: 0 0 60px 0;
}

#page_content .requirements .part-howto p {
  font-size: 14px;
  margin-bottom: 0;
}

.part-howto-tel {
  margin: 20px 0 0 0;
}
.part-howto-tel p {
  margin-top: 10px;
}

.part-howto .mail {
  margin: 30px 0 0 0;
}
.part-howto .mail-link a {
  margin: 10px 0 0 0;
  display: block;
  width: 220px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  color: #fff;
  background-color: #be1e2d;
}

#content .learn .hs_top {
  margin-bottom: 40px;
}
#content .learn h3 {
  margin-bottom: 30px;
}
#content .learn .top ul li {
  width: 227px;
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
}
#content .learn .top ul li:nth-child(3n) {
  margin-right: 0;
}
#content .learn .numeral ul li:nth-child(2n) {
  margin-right: 0;
}
#content .learn .top ul li img {
  width: 100%;
}
#content .learn .numeral ul {
  margin-left: 15px;
}
#content .learn .numeral ul li {
  width: 322px;
  float: left;
  margin-right: 25px;
  margin-bottom: 25px;
}
#content .learn .numeral ul li:nth-child(3n) {
  margin-bottom: 0;
}
#content .learn .learn_content {
  margin-bottom: 50px;
}
#content .learn .learn_content p {
  font-size: 15px;
}
#content .learn .learn_content p.nomargin {
  margin: 0;
}
#content .learn .learn_content .imgR,
#content .learn .learn_content .imgL {
  margin-top: 30px;
}
#content .learn .learn_content .imgR img {
  float: right;
  margin-left: 30px;
}
#content .learn .learn_content .imgL img {
  float: left;
  margin-right: 30px;
}
#content .learn .learn_message {
  border: 2px solid #e2f0db;
  padding: 20px 25px;
  margin-bottom: 50px;
}
#content .learn .learn_message h4 {
  color: #69b143;
  width: 410px;
  border-bottom: 1px solid #69b143;
  font-size: 20px;
  margin-bottom: 20px;
}
#content .learn .learn_message p {
  width: 410px;
  font-size: 15px;
}
#content .learn .learn_message .comment {
  width: 410px;
  font-size: 15px;
  float: left;
}
#content .learn .learn_message img {
  float: right;
}

#page_content .category_content {
  margin-bottom: 60px;
}
#page_content .category_content .category_title {
  padding: 10px;
  background: #ffd5d6;
  clear: both;
  overflow: hidden;
  margin-bottom: 20px;
}
#page_content .category_content .category_title h3 {
  float: left;
  width: 600px;
  font-weight: normal;
}
#page_content .category_content .category_title p {
  float: right;
  width: 193px;
  font-size: 14px;
}
#page_content .category_content h5 {
  width: 400px;
  text-align: center;
  padding: 10px 0;
  text-align: center;
  font-weight: normal;
  margin: 0 auto;
  border: 1px solid black;
  margin-top: 40px;
}
#page_content .category_content h5 a {
  color: black;
  text-decoration: none;
  display: block;
  font-family: serif;
}
#page_content .single_content img {
  padding: 40px 0;
}
#page_content .introduction {
  padding: 20px;
  border: 1px solid silver;
  clear: both;
  overflow: hidden;
  margin-top: 60px;
}
#page_content .introduction h3 {
  margin-bottom: 20px;
  background: #ffd5d6;
  padding: 3px 10px;
  font-weight: normal;
  font-size: 16px;
}
#page_content .introduction figure {
  margin-bottom: 20px;
  border: 1px solid silver;
  float: left;
  width: 250px;
}
#page_content .introduction figure img {
  width: 100%;
}
#page_content .introduction p {
  float: left;
  width: 500px;
  margin-left: 20px;
}
#page_content .introduction h5 {
  margin-top: 20px;
  font-weight: normal;
  border-bottom: 1px solid silver;
  clear: both;
}
#page_content .introduction h5 a {
  color: navy;
}

#smt,
.smt {
  display: none;
}

#smart_nav {
  display: none !important;
}

.navigation {
  clear: both;
  overflow: hidden;
  margin-top: 40px;
}
.navigation p.prev {
  float: left;
}
.navigation p.next {
  float: right;
}
.navigation p.prev a, .navigation p.next a {
  padding: 5px 10px;
  background: #ffd5d6;
  display: block;
}
.navigation p.prev a::before {
  content: url("../img/mobile/prev.png");
  margin: 0px 10px 0px 0px;
  padding-top: 3px;
  vertical-align: middle;
}
.navigation p.next a::after {
  content: url("../img/mobile/next.png");
  margin: 0px 0px 0px 10px;
  padding-top: 3px;
  vertical-align: middle;
}

figure.sidebanner {
  margin-top: 40px;
}

.page404 {
  text-align: center;
  background: url(../img/404.png);
  height: 200px;
  width: 100%;
  padding-top: 80px;
}
.page404 img {
  width: 400px;
  padding-top: 50px;
}

.page404_txt {
  margin-bottom: 100px;
}
.page404_txt p {
  text-align: center;
  padding: 60px 0 40px;
}
.page404_txt a {
  display: block;
  width: 270px;
  margin: 0 auto;
  padding: 10px 0;
  border-radius: 5px;
  background: #ffd5d6;
  text-align: center;
}

/*商品情報問い合わせ*/
#page_content #content .product-form-comment p {
  line-height: 1.8;
  margin-bottom: 20px;
}

.product-form-comment ul li {
  list-style-type: disc;
  margin: 0 0 5px 1em;
}
.product-form-comment ul li a {
  color: blue;
  text-decoration: underline;
  border: none;
}

.product-form a.pr {
  color: blue;
  text-decoration: underline;
  border: none;
}

#page_content .contact table.product-form {
  margin-top: 30px;
  width: 100%;
}
#page_content .contact .product-form th {
  font-size: 15px;
  padding: 15px;
  width: 150px;
}
#page_content .product-form input {
  /*width: auto!important;*/
}
#page_content .product-form tr.kojin input {
  width: auto !important;
  margin-right: 5px;
}
#page_content .product-form tr.address {
  border-right: 1px solid silver;
}
#page_content .product-form tr.address td {
  border: none;
  padding: 10px 20px;
}
#page_content .product-form span.address {
  display: block;
}
#page_content .product-form .btn_area button {
  margin: 0px auto;
  display: inline-block;
  width: 200px;
  padding: 20px 0px;
  background: #ffebd6;
  border: 1px solid silver;
  font-size: 14px;
  letter-spacing: 1px;
}

.product-form table#mfp_confirm_table tr.mfp_colored {
  background-color: #fff;
}

/*中途採用*/
.mid-interview {
  margin: 35px 0;
}

.mid-title {
  border-bottom: 1px solid #000;
  padding-bottom: 10px;
  margin-bottom: 25px;
}

/*2018/02/20*/
.saiyo-message-wrapper {
  background-color: #d7ecfa;
  padding-bottom: 40px;
  margin-bottom: 70px;
}

.saiyo-message-inner {
  max-width: 1000px;
  margin: 0 auto;
  background-image: url(../img/mrmaeda_pc.png);
  background-position: right top;
  background-repeat: no-repeat;
  padding-top: 40px;
  position: relative;
}
.saiyo-message-inner h3 {
  font-size: 35px;
  font-family: serif;
  font-weight: normal;
  padding-bottom: 0px;
  margin-bottom: 20px;
  position: relative;
}
.saiyo-message-inner h3::after {
  content: "";
  border-bottom: 1px solid #000;
  width: 80%;
  position: absolute;
  left: 0;
  bottom: 0;
}
.saiyo-message-inner h3 span {
  font-size: 21px;
  font-weight: normal;
  display: block;
  font-family: serif;
  margin: 15px 0 0 0;
  padding-bottom: 20px;
}

.saiyo-message-reason p {
  line-height: 1.8em;
}

.seminar-link {
  width: 420px;
  height: 70px;
  background-color: #fff;
  line-height: 70px;
  text-align: center;
  font-size: 18px;
  margin: 0 auto;
}

.saiyo-message .seminar-link {
  line-height: 1.6em;
  text-align: left;
  vertical-align: middle;
  height: auto;
  padding: 10px 0 10px 40px;
  position: relative;
}
.saiyo-message .seminar-link a {
  display: block;
}
.saiyo-message .seminar-link::after {
  content: ">";
  position: absolute;
  font-size: 30px;
  top: 20px;
  right: 20px;
}

.part-bosyu .part-title,
.part-saiyo {
  position: relative;
}

.part-bosyu .part-title img {
  vertical-align: middle;
}
.part-bosyu .part-title .icon {
  position: absolute;
  right: 20px;
  top: auto;
  bottom: 5px;
}

.part-saiyo .icon {
  position: absolute;
  right: 20px;
  top: auto;
  bottom: 5px;
  position: absolute;
  right: 70px;
  top: auto;
  bottom: 10px;
}

/*会社見学会&模擬面接セミナー　エントリーフォーム*/
.entry1805 .entry-rashinban {
  margin-bottom: 30px;
}

.side-rashinban {
  margin-bottom: 60px;
}

.entry1805 .entry-rashinban img,
.side-rashinban img {
  max-width: 100%;
  height: auto;
}

.under-rashinban {
  margin-top: 30px;
}
.under-rashinban img {
  max-width: 100%;
  height: auto;
}

.entry1805 .saiyo-message-wrapper {
  margin-bottom: 30px;
}
.entry1805 .saiyo-message-inner {
  max-width: 700px;
  margin: 0 auto;
  background-image: url(../img/mrmaeda_pc.png);
  -webkit-background-size: 180px auto;
  background-size: 180px auto;
  background-position: right 120px;
  background-repeat: no-repeat;
  padding: 40px 20px 0 20px;
  position: relative;
  z-index: 10;
}
.entry1805 .saiyo-message-inner h3 {
  font-size: 26px;
}
.entry1805 .saiyo-message-inner h3 span {
  padding-bottom: 10px;
}

#page_content #nav_side.side3196 ul.un, #page_content #nav_side.side3198 ul.un, #page_content #nav_side.side3203 ul.un, #page_content #nav_side.side4496 ul.un {
  margin-bottom: 30px;
}

.under-pc-rashinban {
  margin-bottom: 60px;
}

@media screen and (max-width: 640px) {
  .entry1805 {
    margin: 0 -20px;
  }
  .entry1805 .saiyo-message-inner {
    background-image: none;
  }
  #saiyouTop div .rec_menuL .vimeo {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;
    margin-bottom: 50px;
  }
  #saiyouTop div .rec_menuL .vimeo iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}
@media screen and (max-width: 640px) {
  body {
    padding-top: 44px;
  }
  p {
    font-size: 14px;
    line-height: 1.3;
  }
  #pc,
  .pc {
    display: none;
  }
  #smt,
  .smt {
    display: block;
  }
  #page_content h3.subtitle {
    margin-bottom: 40px;
    font-weight: normal;
    font-size: 16px;
  }
  #page_content h3.subtitle:before {
    content: "";
  }
  .navigation {
    clear: both;
    overflow: hidden;
    margin: 40px 0;
  }
  #logo {
    position: absolute;
    top: 5px;
    left: 5px;
  }
  #logo img {
    width: 160px;
  }
  #top_main {
    padding-top: 0px;
    text-align: center;
    min-width: 0;
  }
  #top_main img {
    width: auto;
  }
  #top_main .company {
    width: 100%;
    background: url(../img/mobile/top_S_img01.jpg);
    padding: 40px 0;
    background-size: cover;
  }
  #top_main .jikihan {
    width: 100%;
    background: url(../img/mobile/top_S_img02.jpg);
    padding: 40px 0;
    background-size: cover;
  }
  #top_main .project {
    width: 50%;
    background: url(../img/mobile/top_S_img03.jpg);
    float: left;
    padding: 40px 0;
    background-size: cover;
  }
  #top_main .recruit {
    width: 50%;
    background: url(../img/mobile/top_S_img04.jpg);
    float: right;
    padding: 23px 0 24px;
    background-size: cover;
  }
  #top_main .project img {
    width: 148px;
  }
  #top_main .recruit img {
    width: 120px;
  }
  #top_main .makanai {
    background: #577998;
    text-align: center;
    clear: both;
    overflow: hidden;
  }
  #top_main .makanai img {
    width: 100%;
    margin: 0 auto;
  }
  #catch {
    text-align: left;
    margin: 40px auto 60px;
    width: 90%;
  }
  #catch h2 {
    margin-bottom: 40px;
  }
  #catch p {
    line-height: 26px;
    letter-spacing: 0.5px;
  }
  #catch img {
    width: 100%;
  }
  #products_link {
    padding-top: 0px;
    margin-top: 0px;
  }
  #our_products h2 img {
    height: 38px;
  }
  #our_products .flexslider {
    width: 200px;
    margin: 0 auto;
    max-width: 10000000px;
  }
  #our_products .flexslider li {
    width: 200px !important;
  }
  #our_products .flexslider li img {
    max-width: 100% !important;
  }
  #recruit div {
    width: 90%;
  }
  #recruit h2 img {
    height: 38px;
  }
  #recruit div .recL {
    float: none;
    width: 100%;
  }
  #recruit div .recL img {
    width: 100%;
  }
  #recruit .text-center img {
    width: 100%;
  }
  #recruit div .recR {
    float: none;
    width: 100%;
  }
  #recruit div .recR img {
    /* height: 18px; */
  }
  #recruit dt {
    font-size: 14px;
    width: 90px;
  }
  #recruit dd {
    font-size: 14px;
    padding-left: 95px;
  }
  #news h2 img {
    height: 38px;
  }
  #news ul {
    margin: 0 5%;
    width: 90%;
  }
  #news ul li {
    width: 48%;
    margin: 0 4% 20px 0;
    height: auto;
  }
  #news ul li:nth-child(2n) {
    margin-right: 0%;
  }
  #news ul li img {
    width: 100%;
  }
  #news ul li h3 {
    width: 100%;
    font-size: 10px;
  }
  #news ul li h5 {
    font-size: 14px;
    margin-top: 10px;
  }
  #onlineshop_link {
    padding-top: 0px;
    margin-top: 0px;
  }
  #onlineshop h2 img {
    height: 38px;
  }
  #onlineshop p {
    width: 90%;
    margin: 0 5% 40px;
  }
  #onlineshop ul li img {
    width: 60%;
  }
  #onlineshop ul li.online {
    background: transparent url("../img/mobile/top_S_img05.png") repeat scroll 0% 0%;
    width: 100%;
    padding: 30px 0px 20px;
    background-size: cover;
    height: auto;
  }
  #onlineshop ul li.amazon {
    background: transparent url("../img/mobile/top_S_img06.png") repeat scroll 0% 0%;
    width: 100%;
    margin-right: 30px;
    padding: 30px 0px 20px;
    margin-top: 20px;
    background-size: cover;
    height: auto;
  }
  #onlineshop ul li.baranori {
    background: transparent url("../img/mobile/top_S_img07.png") repeat scroll 0% 0%;
    width: 100%;
    padding: 30px 0px 20px;
    margin-top: 20px;
    background-size: cover;
    height: auto;
  }
  #onlineshop ul li figure:first-child, #onlineshop ul li:first-child figure:first-child {
    margin-bottom: 20px;
    padding-top: 0px;
  }
  #onlineshop ul li a {
    display: block;
    height: auto;
  }
  #gmap {
    display: none;
  }
  #page_main {
    padding-top: 0;
    clear: both;
    overflow: hidden;
  }
  #page_main .left {
    background: #bc1e2d none repeat scroll 0% 0%;
    width: 100%;
    text-align: center;
    float: none;
  }
  #page_main .right {
    display: none;
  }
  #pankuzu {
    font-size: 10.5px;
    margin: 20px 5%;
  }
  #page_content {
    max-width: 1140px;
    margin: 0px auto 0px;
    clear: both;
    overflow: hidden;
  }
  #nav_side {
    display: none;
  }
  #nav_side.blog {
    display: block;
  }
  #page_content #content {
    margin: 0 5%;
    width: 90%;
  }
  #page_content h2.title {
    padding: 10px 0 3px;
    font-weight: normal;
    border-bottom: 1px solid #000;
    display: inline-block;
    margin-bottom: 40px;
    font-family: serif;
    font-size: 18px;
  }
  #page_content h2.title.t {
    padding: 0px 0 3px;
    font-weight: normal;
    border-bottom: 1px solid #000;
    display: inline-block;
    margin-bottom: 20px;
    font-family: serif;
    font-size: 18px;
  }
  #page_content #content .tsuhan figure img {
    max-width: 100%;
  }
  #page_content #content .senetu {
    margin-bottom: 60px;
  }
  #page_content #content .senetu figure img {
    max-width: 100%;
  }
  #page_content .project ul li {
    float: left;
    width: 48%;
    margin: 0px 4% 20px 0px;
  }
  #page_content .project ul li h5 {
    font-size: 16px;
    font-weight: normal;
    margin-top: 10px;
  }
  #page_content .project ul li:nth-child(3n) {
    margin-right: 4%;
  }
  #page_content .project ul li:nth-child(2n) {
    margin-right: 0px;
  }
  #page_content .project ul li h3 {
    font-size: 10px;
  }
  #page_content .project ul li h5 {
    font-size: 12px;
  }
  #page_content .category_content h5 {
    width: 100%;
  }
  #page_content .category_content .category_title h3 {
    float: none;
    width: 100%;
    font-weight: normal;
  }
  #page_content .category_content .category_title p {
    float: none;
    width: 100%;
    font-size: 14px;
  }
  #nav_bottom {
    width: 90%;
    margin: 0px auto 0;
    clear: both;
    overflow: hidden;
  }
  #nav_bottom h3 {
    width: 90%;
    margin: 40px auto 0;
    font-size: 14px;
    font-weight: normal;
    padding: 5px 10px;
    background: #ffd5d6;
  }
  #nav_bottom h3:first-child {
    margin-top: 0;
  }
  #nav_bottom ul li {
    font-size: 14px;
    margin: 10px 0px 0px 0px;
    padding-bottom: 10px;
    padding-left: 5%;
    border-bottom: 1px solid silver;
  }
  #nav_bottom ul li img {
    padding: 0 5px 3px 0;
  }
  #page_content .career h3 {
    font-size: 14px;
  }
  #page_content .career .career_scroll li {
    float: none;
    margin-right: 0;
  }
  #page_content .career .career_scroll li a {
    display: block;
    width: 100%;
    height: auto;
    margin: 0 auto 0px;
    background: none !important;
  }
  #page_content .career .career_scroll li a img {
    width: 100%;
  }
  #page_content .career .career_scroll li:nth-child(3) a {
    margin-bottom: 0;
  }
  #page_content .career h4 {
    font-size: 14px !important;
  }
  #page_content .career table {
    border-collapse: collapse !important;
    display: block;
    width: 100%;
  }
  #page_content .career table tbody,
  #page_content .career table tr {
    display: block;
    width: 100%;
  }
  #page_content .career th {
    display: block;
    width: 100% !important;
    box-sizing: border-box;
    margin: 20px 0 0 !important;
    background: white !important;
    text-align: center !important;
    padding: 10px 0 !important;
    font-size: 14px;
  }
  #page_content .career td {
    display: block;
    width: 100% !important;
    box-sizing: border-box;
    border: none !important;
    padding: 10px 0 0 0 !important;
    font-size: 14px;
    line-height: 160%;
  }
  #page_content #content .career .teach {
    padding: 20px 25px;
  }
  #page_content #content .career .teach img {
    width: 100%;
  }
  #page_content .person figure img {
    max-width: 100%;
  }
  #smt_pager {
    clear: both;
    overflow: hidden;
    padding: 40px 0;
  }
  #smt_pager a {
    font-size: 10px;
    padding: 10px 0;
    display: block;
  }
  #smt_pager .prev {
    width: 49.8%;
    float: left;
    background: #ffd5d6;
    text-align: left;
  }
  #smt_pager .next {
    width: 49.8%;
    float: right;
    background: #ffd5d6;
    text-align: right;
  }
  #smt_pager img {
    padding: 0 5px 3px;
    width: 10px;
  }
  #page_content .recruit_button ul {
    clear: both;
    overflow: hidden;
    margin-bottom: 20px;
    border-bottom: 1px solid silver;
  }
  #page_content .recruit_button ul li {
    float: left;
    width: 48%;
    margin-right: 4%;
    margin-bottom: 20px;
  }
  #page_content .recruit_button ul li:nth-child(2n) {
    margin-right: 0%;
  }
  #page_content .recruit_button ul li a {
    margin-right: 0px;
    float: none;
    background: none !important;
    width: auto !important;
    height: auto !important;
    display: block;
  }
  #page_content .recruit_button_un ul li a {
    margin-right: 0px;
    float: none;
    background: none !important;
    width: auto !important;
    height: auto !important;
    display: block;
    margin-bottom: 20px;
  }
  #page_content .recruit_button ul li a img {
    width: 100%;
  }
  #page_content .recruit_content {
    margin: 0px 0 0;
    padding-top: 60px;
  }
  #page_content .recruit_content h3 img,
  #page_content .recruit_content figure img {
    max-width: 100%;
  }
  #page_content .recruit_content.top {
    margin: -60px 0 0;
    padding-top: 60px;
  }
  #page_content .maedaya_button {
    width: 90%;
    margin: 0 auto;
  }
  .maedaya_button img.title {
    margin-bottom: 20px;
  }
  .maedaya_button ul {
    margin-bottom: 250px !important;
  }
  .maedaya_button li {
    float: left;
    width: 48%;
    margin-right: 4% !important;
    margin: 0 auto;
    margin-bottom: 10px;
  }
  .maedaya_button ul li:nth-child(2n) {
    margin-right: 0% !important;
  }
  .maedaya_button ul li a img {
    width: 100%;
  }
  #page_content #content .hs_menu,
  #page_content #content .un_menu {
    display: none;
  }
  #page_content #content .hs_smt_menu,
  #page_content #content .un_smt_menu,
  #page_content #content .part_smt_menu {
    margin-bottom: 50px;
  }
  #page_content #content .hs_smt_menu .hs_menutitle {
    width: 100%;
    background: #00aca1;
    color: #fff;
    font-size: 22px;
    text-align: center;
    line-height: 47px;
    border-radius: 10px;
    margin-bottom: 15px;
  }
  #page_content #content .un_smt_menu .un_menutitle,
  #page_content #content .mid_smt_menu .mid_menutitle {
    width: 100%;
    background: #5380c2;
    color: #fff;
    font-size: 22px;
    text-align: center;
    line-height: 47px;
    border-radius: 10px;
    margin-bottom: 15px;
  }
  #page_content #content .part_smt_menu .part_menutitle {
    width: 100%;
    background: #ef9091;
    color: #fff;
    font-size: 22px;
    text-align: center;
    line-height: 47px;
    border-radius: 10px;
    margin-bottom: 15px;
  }
  #page_content #content .hs_smt_menu li {
    width: 100%;
    border: 2px solid #e6f1e1;
    font-size: 14px;
    line-height: 45px;
    margin-bottom: 5px;
    background-image: url(../img/top_recruit_icon.png);
    background-repeat: no-repeat;
    background-position: 90% 50%;
  }
  #page_content #content .hs_smt_menu li a {
    display: block;
    width: 90%;
    padding-left: 10%;
    height: 45px;
  }
  #page_content #content .un_smt_menu li {
    width: 100%;
    border: 2px solid #ddeffa;
    font-size: 14px;
    line-height: 45px;
    margin-bottom: 5px;
    background-image: url(../img/top_recruit_icon.png);
    background-repeat: no-repeat;
    background-position: 90% 50%;
  }
  #page_content #content .un_smt_menu.smt li a {
    display: block;
    width: 90%;
    padding-left: 10%;
    height: 45px;
  }
  #page_content #content .part_smt_menu li {
    width: 100%;
    border: 2px solid #ffb5c2;
    font-size: 14px;
    line-height: 45px;
    margin-bottom: 5px;
    background-image: url(../img/top_recruit_icon.png);
    background-repeat: no-repeat;
    background-position: 90% 50%;
  }
  #page_content #content .part_smt_menu.smt li a {
    display: block;
    width: 100%;
    padding-left: 10%;
    height: 45px;
  }
  #page_content .requirements table {
    margin: 0;
    display: block;
    width: 100%;
  }
  #page_content .requirements table tbody,
  #page_content .requirements table tr {
    margin: 0;
    display: block;
    width: 100%;
  }
  #page_content .requirements table th {
    padding: 10px;
    display: block;
    width: 100%;
    border-bottom: none;
    font-size: 14px;
    font-weight: bold;
  }
  #page_content .requirements table td {
    width: 100%;
    display: block;
    padding: 10px;
    border-top: none;
    font-size: 14px;
    line-height: 1.5em;
  }
  #page_content .contact table {
    margin: 0;
    width: 100%;
    display: block;
  }
  #page_content .contact table tbody {
    display: block;
    width: 100%;
  }
  #page_content .contact table tr {
    display: block;
    width: 100%;
  }
  #page_content .contact table tr.place {
    border-right: 1px solid silver;
    box-sizing: border-box;
    border-bottom: 1px solid silver;
  }
  #page_content .contact table tr.place th {
    border-right: none;
  }
  #page_content .contact table tr.place td {
    border-right: none;
    border-bottom: none;
  }
  #page_content .contact table tr:first-child th {
    border-top: 1px solid silver;
  }
  #page_content .contact table th {
    padding: 10px 0 10px 5%;
    display: block;
    width: 100%;
    border-top: none;
    font-size: 14px;
    font-weight: normal;
    box-sizing: border-box;
  }
  #page_content .contact table td {
    width: 100%;
    display: block;
    padding: 10px 0 10px 5%;
    border-top: none;
    font-size: 14px;
    box-sizing: border-box;
    max-width: 100%;
  }
  #page_content .contact .privacy {
    margin: 40px 0 0;
  }
  #page_content .contact .mfp_buttons button {
    margin: 0px auto 40px;
    display: block;
    width: 200px;
    padding: 20px 0px;
    background: #ffebd6 none repeat scroll 0% 0%;
    border: 1px solid #c0c0c0;
    font-size: 14px;
    letter-spacing: 1px;
  }
  #page_content #content {
    float: none;
  }
  #page_content .contact table {
    width: 100% !important;
    border: none !important;
  }
  #page_content .contact .product-form th,
  #page_content .contact .product-form td {
    width: 100% !important;
    border: none !important;
  }
  #page_content .product-form tr.address {
    width: 100% !important;
    border: none !important;
  }
  #page_content .product-form tr.address th {
    width: 100% !important;
    border: none !important;
  }
  #page_content .product-form #mfp_confirm_table {
    width: 100% !important;
    border: none !important;
  }
  #page_content .product-form #mfp_confirm_table th,
  #page_content .product-form #mfp_confirm_table td {
    width: 100% !important;
    border: none !important;
  }
  #page_content .product-form #mfp_confirm_table tr.address {
    width: 100% !important;
    border: none !important;
  }
  #page_content .product-form #mfp_confirm_table tr.address th {
    width: 100% !important;
    border: none !important;
  }
  #page_content .product-form table tr:first-child th {
    width: 100% !important;
    border: none !important;
  }
  #page_content .contact table td,
  #page_content .contact table th,
  #page_content .contact table tr:first-child th {
    width: 100% !important;
    border: none !important;
  }
  #page_content .product-form tr.address td {
    padding: 10px;
  }
  #page_content .product-form input {
    width: 90% !important;
  }
  #page_content .product-form input[type=date] {
    display: block;
  }
  #page_content .product-form input[type=text], #page_content .product-form input[type=email] {
    width: 90% !important;
  }
  #page_content .product-form textarea {
    width: 90% !important;
  }
  #page_content .product-form select option {
    padding: 5px;
  }
  #page_content .product-form select.month, #page_content .product-form select.day {
    margin-bottom: 5px;
    /*width: 50%;*/
  }
  #page_content .contact table.product-form td {
    padding: 10px 15px;
  }
  #page_content .product-form tr.kojin input {
    width: auto !important;
    margin: 0 5px 5px 0;
  }
  #page_content .product-form tr.kojin label {
    margin: 0 0 5px 0;
    display: block;
  }
  #page_content #content .product-form tr.kojin p {
    line-height: 1.3em;
  }
  #page_content .product-form th {
    width: 100%;
    display: block;
  }
  #page_content .product-form th br {
    display: none;
  }
  #page_content .product-form tr.address {
    border-left: 1px solid silver;
    width: auto;
  }
  #page_content .product-form tr.address th {
    border-right: none;
    border-left: none;
  }
  /*商品情報 お問い合わせ*/
  .product-form-comment ul li {
    line-height: 1.3;
  }
  img.mailaddress {
    height: 17px;
  }
  #page_content .entry table {
    margin: 40px 0 0;
    display: block;
    width: 100%;
  }
  #page_content .entry table tbody {
    display: block;
    width: 100%;
  }
  #page_content .entry table tr {
    display: block;
    width: 100%;
  }
  #page_content .entry table tr.place {
    border-right: 1px solid silver;
    box-sizing: border-box;
    border-bottom: 1px solid silver;
  }
  #page_content .entry table tr.place th {
    border-right: none;
  }
  #page_content .entry table tr.place td {
    border-right: none;
    border-bottom: none;
  }
  #page_content .entry table tr:first-child th {
    border-top: 1px solid silver;
  }
  #page_content .entry table th {
    padding: 10px;
    display: inline-block;
    width: 100%;
    border-top: none;
    font-size: 14px;
    font-weight: normal;
    box-sizing: border-box;
  }
  #page_content .entry table td {
    max-width: 100%;
    display: block;
    padding: 10px;
    border-top: none;
    font-size: 14px;
    box-sizing: border-box;
    width: 400px;
  }
  #page_content .entry .mfp_buttons button {
    margin: 0px auto;
    display: block;
    width: 200px;
    padding: 20px 0px;
    background: #f7f5f1 none repeat scroll 0% 0%;
    border: 1px solid #c0c0c0;
    font-size: 14px;
    letter-spacing: 1px;
    margin-top: 40px;
  }
  #page_content .thanks h1 {
    font-weight: normal;
    padding: 0 0 5px 0;
    border-bottom: 1px solid black;
    width: 100%;
    font-size: 16px;
    text-align: center;
    margin: 0 auto 40px;
  }
  #page_content .thanks p {
    text-align: left;
  }
  #page_content .makanai .product_main .image {
    float: none;
    margin-right: 0px;
  }
  #page_content .makanai .product_main .image img {
    width: 100%;
  }
  #page_content .makanai .product_main .detail {
    float: none;
    margin-top: 40px;
  }
  #page_content .makanai .product_main .detail h2 {
    margin: 0px 0px 10px;
    border: none;
    font-family: inherit;
  }
  #page_content .makanai figure img {
    max-width: 100%;
  }
  #page_content .makanai .radio {
    display: block;
  }
  #page_content .makanai .radio .comment,
  #page_content .makanai .radio figure {
    display: block;
  }
  #page_content .makanai .radio .comment {
    padding: 0;
  }
  #page_content .makanai .radio .comment p {
    letter-spacing: 0;
  }
  #page_content .makanai figure {
    margin: 5% 0;
  }
  #page_content .product .product_main .image {
    float: none;
    margin-right: 0px;
  }
  #page_content .product .product_main .image img {
    width: 100%;
  }
  #page_content .product .product_main .detail {
    float: none;
    margin-top: 40px;
  }
  #page_content .product .product_main .detail h2 {
    margin: 0px 0px 10px;
    border: none;
    font-family: inherit;
  }
  #page_content .product figure img {
    max-width: 100% !important;
  }
  #page_content .product .product_main .detail {
    margin: 20px 0 0 0;
  }
  .product-title span {
    margin: 10px 0;
  }
  #page_content .company_gaiyou table {
    margin: 0px 0 60px;
    display: block;
    width: 100%;
  }
  #page_content .company_gaiyou table tbody,
  #page_content .company_gaiyou table tr {
    display: block;
    width: 100%;
  }
  #page_content .company_gaiyou table th {
    padding: 10px;
    display: block;
    width: 100%;
    border-bottom: none;
    font-size: 14px;
    font-weight: bold;
  }
  #page_content .company_gaiyou table td {
    width: 100%;
    display: block;
    padding: 5px 10px;
    border-top: none;
    font-size: 14px;
  }
  #page_content .company_gaiyou figure img {
    width: 100%;
  }
  #page_content .company_access iframe {
    width: 100% !important;
    height: 300px !important;
  }
  #page_content .history table {
    margin: 0px 0 0px;
    display: block;
    width: 100%;
  }
  #page_content .history table tbody,
  #page_content .history table tr {
    display: block;
    width: 100%;
  }
  #page_content .history table th {
    padding: 10px 0px 0;
    display: block;
    width: 100%;
    border-bottom: none;
    font-size: 14px;
    font-weight: bold;
  }
  #page_content .history table td {
    width: 100%;
    display: block;
    padding: 5px 0 10px 0;
    border-top: none;
    font-size: 14px;
  }
  #page_content .shokuhin figure {
    text-align: center;
  }
  #page_content .shokuhin figure img {
    height: auto;
    max-width: 100%;
    box-sizing: border-box;
  }
  #page_content .shokuhin figure img:first-child {
    margin-right: 0px;
    margin-bottom: 20px;
  }
  #page_content .eisei figure {
    margin: 40px 0 0 0px;
    text-align: center;
  }
  #page_content .eisei figure img {
    padding-right: 0px;
    max-width: 40%;
  }
  #page_content .eisei figure img:first-child {
    padding-right: 0px;
    padding-bottom: 20px;
    max-width: 80%;
  }
  #page_content .introduction {
    padding: 5%;
    border: 1px solid silver;
    clear: both;
    overflow: hidden;
  }
  #page_content .introduction h3 {
    margin-bottom: 20px;
    background: #ffd5d6;
    padding: 3px 10px;
    font-weight: normal;
    font-size: 16px;
  }
  #page_content .introduction figure {
    margin-bottom: 20px;
    border: 1px solid silver;
    float: none;
    width: auto;
  }
  #page_content .introduction p {
    float: none;
    width: auto;
    margin-left: 0px;
  }
  #page_content .introduction h5 {
    margin-top: 20px;
    font-weight: normal;
    border-bottom: 1px solid silver;
    clear: both;
  }
  #page_content .introduction h5 a {
    color: navy;
  }
  #page_content .think figure img {
    padding-right: 0;
    width: 100%;
    height: auto;
  }
  #page_content .think h5 {
    font-size: 14px;
  }
  #page_content h5.entry a {
    background: url("../img/entry_s.png") left top no-repeat;
    width: 100%;
    height: 0;
    padding-bottom: 18.893617%;
    background-size: contain;
  }
  #page_content #content .event div.eventTitle {
    display: block;
  }
  #page_content .event figure img {
    max-width: 100%;
  }
  #page_content #content .event figure.eventImg {
    display: none;
  }
  #page_content #content .event div h2 {
    padding: 30px 20px 10px 20px;
    margin: 0;
  }
  #page_content #content .event p.date {
    padding: 0 0 30px 20px;
  }
  #page_content .event div .btn {
    text-align: center;
  }
  /*採用トップ*/
  #page_main .left img.saiyouTitle {
    height: auto;
    width: 245px;
    margin: 0 auto;
  }
  #saiyouTop .smt {
    text-align: center;
  }
  #saiyouTop .smt .top {
    width: 90%;
  }
  #saiyouTop h2 {
    margin-bottom: 40px;
    text-align: center;
  }
  #saiyouTop .saiyouContents {
    width: 100%;
    background-color: #fff5dc;
    margin: 50px 0;
    padding: 50px 0;
  }
  #saiyouTop .saiyouTable {
    width: 90%;
    margin: 0 auto;
  }
  #saiyouTop .saiyouTable img {
    width: 100%;
  }
  #saiyouTop .saiyouTable li {
    float: none;
    margin-right: 0;
    margin-bottom: 20px;
  }
  #saiyouTop .saiyouTable li:nth-child(3n) {
    margin-right: 0%;
  }
  #saiyouTop .saiyouInfo img {
    width: 100%;
    height: auto;
    margin-bottom: 15px;
  }
  #saiyouTop div .rec_menu {
    width: 90%;
    margin: 0 auto 70px;
  }
  #saiyouTop div .rec_menuL {
    width: 100%;
    float: none;
    margin-bottom: 50px;
  }
  #saiyouTop div .rec_menuR {
    width: 100%;
    float: none;
  }
  #saiyouTop div .rec_menuC {
    margin: 0 20px;
  }
  #saiyouTop div #parttime {
    margin-top: -50px;
    padding-top: 50px;
  }
  .parttime_video {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
  }
  .parttime_video iframe {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
  .ytp-cued-thumbnail-overlay {
    max-width: 100%;
  }
  #saiyouTop div .rec_menuL li {
    border: 2px solid #e6f1e1;
    font-size: 14px;
    line-height: 40px;
    margin-bottom: 5px;
    position: relative;
  }
  #saiyouTop div .rec_menuR li {
    border: 2px solid #d7ecfa;
    font-size: 16px;
    line-height: 40px;
    margin-bottom: 5px;
    position: relative;
  }
  #saiyouTop div .rec_menuL li a,
  #saiyouTop div .rec_menuR li a {
    display: block;
    width: auto;
    padding-left: 15%;
    line-height: 40px;
  }
  #saiyouTop div .rec_menuL li span,
  #saiyouTop div .rec_menuR li span {
    position: absolute;
    right: 40px;
    top: 4px;
  }
  #saiyouTop div #part_menu li span {
    top: -2px;
  }
  #saiyouTop div .rec_menuL li a:hover {
    background: #e6f1e1;
  }
  #saiyouTop div .rec_menuR li a:hover {
    background: #d7ecfa;
  }
  #saiyouTop .maedaya {
    display: none;
  }
  #saiyouTop .smt .maedaya_button {
    width: 90%;
    margin: 0 auto;
  }
  /*蜑咲伐螻九〒蟄ｦ縺ｶ*/
  #content .learn img {
    width: 100%;
  }
  #content .learn h2,
  #content .learn h3 {
    margin-bottom: 25px;
  }
  #content .learn .top ul li {
    width: 80%;
    margin: 0 auto 10px;
    float: none;
  }
  #content .learn .top ul li:nth-child(3n) {
    margin: 0 auto;
  }
  #content .learn .learn_content {
    margin-bottom: 50px;
  }
  #content .learn .learn_content p {
    font-size: 15px;
  }
  #content .learn .learn_content .imgR,
  #content .learn .learn_content .imgL {
    margin-top: 30px;
  }
  #content .learn .learn_content .imgR img,
  #content .learn .learn_content .imgL img {
    float: none;
    margin: 20px 0 0 0;
  }
}
@media screen and (max-width: 640px) and (max-width: 640px) {
  #content .learn .learn_content .imgR img,
  #content .learn .learn_content .imgL img {
    padding: 0 20px;
  }
}
@media screen and (max-width: 640px) {
  #content .learn .learn_message {
    border: 2px solid #e2f0db;
    padding: 5% 7%;
    margin-bottom: 40px;
  }
  #content .learn .learn_message h4 {
    width: 100%;
    font-size: 20px;
    margin-bottom: 20px;
  }
  #content .learn .learn_message p {
    width: 100%;
    margin-bottom: 15px;
  }
  #content .learn .learn_message .comment,
  #content .learn .learn_message img {
    float: none;
    width: 100%;
  }
  footer .footer_link {
    width: 100%;
    margin: 0;
  }
  footer .footer_link p {
    margin: 0px 0px 0px 0px;
  }
  ul.accordion {
    background: #ddd;
    width: 100%;
    margin: 0 auto;
    padding: 0px;
    font-size: 16px;
  }
  ul.accordion a {
    display: block;
    padding: 10px;
    background-position: 97% center;
    background-repeat: no-repeat;
    text-decoration: none;
    color: #333;
    font-weight: normal;
    font-size: 14px;
  }
  ul.accordion span {
    display: block;
    padding: 10px;
    background-position: 97% center;
    background-repeat: no-repeat;
    color: #333;
    font-weight: normal;
    font-size: 14px;
  }
  ul.accordion ul {
    display: none;
  }
  ul.accordion > li {
    background: #eee;
    border-bottom: 1px solid white;
  }
  ul.accordion > li > p {
    background: #eee;
  }
  ul.accordion > li > p span {
    background-image: url(../img/mobile/open.png);
    background-size: 5%;
  }
  ul.accordion > li > p span.open {
    background-image: url(../img/mobile/close.png);
  }
  ul.accordion > li > ul > li {
    background: #e7e7e7;
    border-bottom: 1px solid #eee;
  }
  ul.accordion > li > ul > li > p {
    background: #999;
  }
  ul.accordion > li > ul > li > a {
    background: url(https://www.nori-maedaya.com/common/img/go.png) no-repeat 98% center;
  }
  ul.accordion > li > ul > li > ul > li {
    background: #fff;
    border-bottom: 1px dotted #888;
  }
  ul.accordion > li > ul > li > ul > li:last-child {
    border: none;
  }
  ul.accordion li ul .subtitle {
    display: block;
    padding: 10px;
    background-position: 97% center;
    background-repeat: no-repeat;
    text-decoration: none;
    color: #333;
    font-weight: normal;
    font-size: 14px;
  }
  ul.accordion li ul .submenu {
    padding-left: 10px;
  }
  footer .footer_content {
    background: transparent url("../img/top_footer.png") repeat scroll 0% 0%/cover;
    padding: 20px 5%;
  }
  footer .footer_content .left {
    float: none;
    color: #fff;
  }
  footer .footer_content .left p {
    font-size: 12px;
  }
  footer .footer_content .right {
    float: none;
    margin-top: 10px;
  }
  footer .footer_content .right img {
    width: 150px;
  }
  footer .footer_content .copy {
    margin-top: 20px;
  }
  footer .footer_content .copy p {
    font-size: 12px;
  }
  #page-top {
    position: fixed;
    bottom: 10px;
    right: 10px;
    font-size: 77%;
  }
  #page-top a {
    text-decoration: none;
    color: #fff;
    width: 50px;
    padding: 0px 0;
    text-align: center;
    display: block;
  }
  #page-top img {
    width: 50px;
  }
  .page404 {
    text-align: center;
    background: url(../img/404.png);
    background-position: center center;
    height: auto;
    width: 100%;
    padding-top: 0px;
  }
  .page404 img {
    width: 80%;
    padding: 40px 0;
  }
  .page404_txt {
    margin-bottom: 60px;
  }
  .page404_txt p {
    text-align: left;
    padding: 60px 0 40px;
    width: 90%;
    margin: 0 auto;
  }
  .page404_txt a {
    display: block;
    width: 270px;
    margin: 0 auto;
    padding: 10px 0;
    border-radius: 5px;
    background: #ffd5d6;
    text-align: center;
    font-size: 14px;
  }
  .saiyou_fix {
    position: fixed;
    top: 48px;
    background-color: #fff;
    z-index: 100;
  }
  .saiyou_fix li {
    border-top: 1px solid #be1e2d;
  }
  .saiyou_fix li:last-child {
    border-right: 1px solid #be1e2d;
  }
  .saiyou {
    display: table;
    width: 100%;
  }
  .saiyou li {
    display: table-cell;
    vertical-align: middle;
    border-left: 1px solid #be1e2d;
    border-bottom: 1px solid #be1e2d;
    padding: 10px;
    width: 50%;
  }
  .saiyou li a {
    color: #be1e2d;
    font-size: 0.813em;
  }
  #hs_menu,
  #un_menu,
  #part_menu {
    margin-top: -150px;
    padding-top: 150px;
  }
  #content .learn .top ul li:nth-child(3n) {
    margin: 10px auto;
  }
  #content .learn .numeral ul {
    margin-left: 0;
    text-align: center;
  }
  #content .learn .numeral ul li {
    float: none;
    margin-right: 0;
    width: 100%;
  }
  #saiyouTop .saiyouInfo .part-saiyo img.icon {
    width: 120px;
    height: auto;
  }
  .part-saiyo .icon {
    position: absolute;
    right: 60px;
    top: 12px;
    bottom: 0;
  }
}
@media screen and (max-width: 768px) {
  ul.nav-saiyou {
    display: block;
    border-left: 1px solid #be1e2d;
    box-sizing: border-box;
    overflow: hidden;
  }
  ul.nav-saiyou li {
    display: inline-block;
    list-style-type: none;
    box-sizing: border-box;
    width: 50%;
    padding: 10px;
    border-right: 1px solid #be1e2d;
    /*border-top:1px solid #BE1E2D;*/
    border-bottom: 1px solid #be1e2d;
    border-left: none;
  }
  ul.nav-saiyou li:last-child {
    border-top: none;
  }
  ul.nav-saiyou li a {
    color: #be1e2d;
    font-size: 0.813em;
  }
  #saiyouTop .top-part-inner {
    width: 90%;
    margin: 0 auto 60px auto;
  }
  #saiyouTop .top-part-inner h3 img,
  #saiyouTop .top-part-inner figure img {
    max-width: 100%;
    height: auto;
  }
  /*  #saiyouTop .top-part-inner h2{
    font-size: 26px;
  }*/
  .part img,
  #page_content .product h2 img {
    max-width: 100%;
    height: auto;
  }
  #saiyouTop .top-part-inner ul li a {
    line-height: 40px;
  }
  #saiyouTop .top-part-inner ul li span {
    top: 10px;
    right: 40px;
  }
  .part-work-video {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
  }
  .part-work-video iframe {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
  /*.part-work h2 img,
  .part-bosyu h2 img,
  .part-entry h2 img,
  .part-howto h2 img{
    max-width: 80%;
  }*/
  .baranori-award h3 {
    font-size: 21px;
  }
  .award-link a {
    font-size: 14px;
  }
  .un_entry_comment_inner {
    display: block;
    margin: 30px 0;
    line-height: 2em;
  }
  .un_entry_comment_inner .un_entry_comment_photo {
    margin: 30px 0;
    text-align: center;
  }
  .saiyo-message-wrapper {
    background-color: #d7ecfa;
    padding-bottom: 40px;
    margin-bottom: 70px;
  }
  .saiyo-message-inner {
    max-width: 100%;
    margin: 0 auto;
    background-image: none;
    background-position: right top;
    background-repeat: no-repeat;
    padding: 20px;
    position: relative;
  }
  .saiyo-message-inner h3 {
    font-size: 26px;
    font-family: serif;
    padding-bottom: 0px;
    margin-bottom: 20px;
    position: relative;
  }
  .saiyo-message-inner h3::after {
    content: "";
    border-bottom: 1px solid #000;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
  }
  .saiyo-message-inner h3 span {
    font-size: 18px;
    font-weight: normal;
    display: block;
    font-family: serif;
    margin: 15px 0 0 0;
    padding-bottom: 20px;
  }
  .saiyo-message-reason p {
    line-height: 1.8em;
  }
  .saiyo-message_photo {
    margin-top: 30px;
    padding: 0 20px;
  }
  .saiyo-message_photo img {
    max-width: 100%;
    height: auto;
  }
  .seminar-link {
    width: 90%;
    height: 70px;
    background-color: #fff;
    line-height: 70px;
    text-align: center;
    font-size: 16px;
    margin: 0 auto;
  }
  .saiyo-message .seminar-link {
    padding: 10px 0 10px 20px;
  }
}
@media screen and (max-width: 320px) {
  .saiyo-message-inner h3 {
    font-size: 23px;
    font-family: serif;
    padding-bottom: 0px;
    margin-bottom: 20px;
    position: relative;
  }
  .saiyo-message-inner h3::after {
    content: "";
    border-bottom: 1px solid #000;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
  }
  .saiyo-message-inner h3 span {
    font-size: 16px;
    font-weight: normal;
    display: block;
    font-family: serif;
    margin: 15px 0 0 0;
    padding-bottom: 20px;
  }
  .seminar-link {
    font-size: 14px;
  }
  .un_entry_comment_photo img {
    width: 100%;
  }
  #saiyouTop #part_menu ul li a {
    padding-left: 10px;
  }
  #saiyouTop .saiyouInfo .part-saiyo img.icon {
    right: 40px;
  }
  #saiyouTop #part_menu ul li span {
    /* right: 12px; */
  }
}
/*media_page*/
.media_box_title {
  margin-bottom: 10px;
}
.media_box_title h2 {
  display: inline-block;
  border-bottom: 3px solid #149d8f;
}

.media_box {
  margin-bottom: 60px;
}

.media_tv::before {
  content: url(../img/media_icon_tv.png);
  display: inline-block;
  margin-right: 10px;
  vertical-align: bottom;
}

.media_radio::before {
  content: url(../img/media_icon_radio.png);
  display: inline-block;
  margin-right: 10px;
  vertical-align: bottom;
}

.media_box_inner {
  display: flex;
}

.media_box_inner_left video {
  width: 300px;
  height: 169px;
}

.media_box_inner_right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.media_box_inner_right_main {
  padding-left: 20px;
}

.media_box_inner_right_date {
  text-align: right;
}

.media_box_attention {
  margin-top: 10px;
}

@media screen and (max-width: 640px) {
  .media_box_title h2 {
    font-size: 16px;
  }
  .media_tv::before {
    content: "";
    display: inline-block;
    width: 35px;
    height: 30px;
    background-image: url(../img/media_icon_tv.png);
    background-size: contain;
    background-repeat: no-repeat;
    vertical-align: bottom;
    margin-right: 5px;
  }
  .media_radio::before {
    content: "";
    display: inline-block;
    width: 35px;
    height: 35px;
    background-image: url(../img/media_icon_radio.png);
    background-size: contain;
    background-repeat: no-repeat;
    vertical-align: bottom;
    margin-right: 5px;
  }
  .media_box_inner {
    flex-direction: column;
  }
  .media_box_inner_left video {
    width: 100%;
    height: auto;
  }
  .media_box_inner_right {
    flex-direction: column-reverse;
    margin-top: 20px;
  }
  .media_box_inner_right_main {
    padding-left: 0px;
    margin-top: 20px;
  }
  .media_box_inner_right_date {
    text-align: left;
  }
  .media_box_attention {
    display: none;
  }
}
#gmap {
  display: block !important;
}

.map-app {
  display: none;
}

@media (max-width: 670px) {
  #onlineshop {
    padding: 30px 0 0 0;
  }
  .map-wrapper {
    position: relative;
    padding: 30px 0;
    background: url(../img/haikei.png);
  }
  .map-app {
    display: block;
    position: absolute;
    width: 150px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background-color: #f9f9f9;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
    border-radius: 2px;
    bottom: 50px;
    left: 10px;
  }
  .map-app a {
    display: block;
  }
}
.cursor {
  cursor: pointer;
}

/* キャリアアップの羅針盤 */
.careerup img {
  max-width: 100%;
  height: auto;
}
.careerup .subtitle {
  text-align: center;
  color: #fff;
  background-color: #00ac92;
  font-weight: normal;
  padding: 8px;
}

.careerup-main-text,
.careerup-main figure {
  margin-top: 40px;
}

.careerup-senpai {
  margin-top: 80px;
  overflow: hidden;
}
.careerup-senpai .careerup-senpai-title {
  font-size: 18px;
  border-top: 2px solid #00ac92;
  border-bottom: 2px solid #00ac92;
  padding: 17px 15px;
  font-weight: normal;
}
.careerup-senpai .careerup-senpai-inner {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}
.careerup-senpai .careerup-senpai-img {
  width: 40%;
}
.careerup-senpai .careerup-senpai-img img {
  max-width: 100%;
}
.careerup-senpai .careerup-senpai-right {
  width: 60%;
}
.careerup-senpai h3 {
  font-size: 21px;
  font-weight: 400;
}
.careerup-senpai p {
  margin-top: 15px;
}
.careerup-senpai .careerup-senpai-box {
  display: inline-block;
}

.careerup-senpai-box {
  text-align: center;
  margin-left: auto;
  margin-top: 30px;
  float: right;
}
.careerup-senpai-box span,
.careerup-senpai-box a {
  display: block;
}
.careerup-senpai-box span {
  color: #fff;
  background-color: #00ac92;
  padding: 3px;
}
.careerup-senpai-box a {
  background-color: #d7ecfa;
  padding: 15px;
  min-width: 150px;
}
.careerup-senpai-box a b {
  margin-left: 20px;
}

.careerup-time {
  clear: both;
  margin-top: 60px;
}
.careerup-time p,
.careerup-time .careerup-time-img {
  margin-top: 30px;
}

.careerup-voice,
.careerup-table {
  margin-top: 80px;
}

#page_content .careerup-table h2.title {
  margin-bottom: 20px;
}
#page_content .careerup .careerup-table table {
  border-collapse: collapse;
}
#page_content .careerup .careerup-table table.bosyu th {
  width: 200px;
  padding: 30px 0;
  text-align: left;
  background: none;
  border-bottom: 1px solid #d8dada;
}
#page_content .careerup .careerup-table table.bosyu td {
  padding: 30px 0;
  text-align: left;
  background: none;
  border-bottom: 1px solid #d8dada;
}

.careerup-table table th {
  padding-right: 40px;
}
.careerup-table table td {
  padding-left: 40px;
}
.careerup-table h3 {
  margin-top: 80px;
}

.careerup-faq {
  margin-top: 80px;
}
.careerup-faq h3 {
  font-size: 18px;
  padding: 12px 0;
  font-weight: normal;
  border-top: 2px solid #b8daa6;
  border-bottom: 2px solid #b8daa6;
  display: flex;
  align-items: center;
}
.careerup-faq h3:before {
  content: "Q";
  color: #b8daa6;
  font-size: 30px;
  padding-right: 15px;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.careerup-faq p {
  margin-top: 20px;
  margin-bottom: 35px;
}

@media screen and (max-width: 768px) {
  #page_content .company_gaiyou table th,
  #page_content .requirements table th,
  #page_content .careerup .careerup-table table.bosyu th {
    width: 100%;
  }
  .careerup-main .careerup-main-img {
    margin-left: -5%;
    margin-right: -5%;
  }
  .careerup-main-text {
    text-align: center;
  }
  .careerup-senpai {
    margin-top: 40px;
  }
  .careerup-senpai .careerup-senpai-title {
    padding-left: 0;
    padding-right: 0;
    font-size: 17px;
  }
  .careerup-senpai .careerup-senpai-inner {
    display: block;
  }
  .careerup-senpai .careerup-senpai-img {
    width: 100%;
  }
  .careerup-senpai .careerup-senpai-img img {
    width: 100%;
  }
  .careerup-senpai .careerup-senpai-right {
    width: 100%;
    margin-top: 20px;
  }
  .careerup-senpai h3 {
    font-size: 18px;
  }
  .careerup-senpai .careerup-senpai-box {
    float: none;
    margin-left: auto;
    margin-right: auto;
  }
  .careerup .subtitle {
    width: 111%;
    margin-left: -6%;
    overflow: hidden;
    font-size: 16px;
  }
  .careerup-voice {
    margin-top: 30px;
  }
  .careerup-table {
    margin-top: 30px;
  }
  .careerup-table table th,
  .careerup-table table td {
    display: block;
    padding: 0;
  }
  .careerup-table table th {
    padding-top: 15px;
    border: none;
  }
  .careerup-table table td {
    padding-top: 5px;
    padding-bottom: 15px;
  }
  #page_content h3.subtitle {
    margin-top: 30px;
    margin-bottom: 20px;
  }
  .careerup-faq {
    margin-top: 40px;
  }
  .careerup-faq h3 {
    font-size: 16px;
  }
  .careerup-faq h3:before {
    font-size: 20px;
    padding-right: 5px;
  }
  .careerup-faq p {
    margin-top: 15px;
    margin-bottom: 30px;
  }
  .careerup-senpai-center {
    text-align: center;
  }
  /* Rules for sizing the icon. */
  .material-icons.md-18 {
    font-size: 18px;
  }
  .material-icons.md-24 {
    font-size: 24px;
  }
  .material-icons.md-36 {
    font-size: 36px;
  }
  .material-icons.md-48 {
    font-size: 48px;
  }
  .material-icons.md-dark {
    color: rgba(0, 0, 0, 0.54);
  }
  .material-icons.md-dark.md-inactive {
    color: rgba(0, 0, 0, 0.26);
  }
  .material-icons.md-light {
    color: rgb(255, 255, 255);
  }
  .material-icons.md-light.md-inactive {
    color: rgba(255, 255, 255, 0.3);
  }
  /* Rules for using icons as black on a light background. */
  /* Rules for using icons as white on a dark background. */
  .un_maintitle {
    padding-bottom: 10px;
    margin-bottom: 20px;
    font-family: serif;
    font-size: 21px;
    font-weight: normal;
    border-bottom: 1px solid #000;
  }
  .un_title--anchor a {
    padding: 10px 20px;
  }
}
.text-center {
  text-align: center;
}

.mtb30 {
  margin: 30px auto;
}

/*漁師のぶっかけ海苔*/
.ryoshinobukkakenori-japanfood {
  margin: 30px 0;
}
.ryoshinobukkakenori-japanfood--title {
  margin-bottom: 30px;
}
.ryoshinobukkakenori-japanfood--catch {
  font-size: 23px;
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-weight: 700;
  letter-spacing: 0.05em;
  line-height: 1.5652173913;
  margin-bottom: 30px;
}
.ryoshinobukkakenori-japanfood--catch b {
  color: #ff0000;
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-weight: 700;
}
.ryoshinobukkakenori-japanfood--subtitle {
  font-size: 23px;
  letter-spacing: 0.05em;
  line-height: 1.5652173913;
  margin-bottom: 30px;
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-weight: 700;
}
.ryoshinobukkakenori-japanfood--logo {
  margin-bottom: 40px;
}
.ryoshinobukkakenori-japanfood--list {
  border: 1px solid #000;
  padding: 35px;
  line-height: 2;
  letter-spacing: 0.075em;
  margin-bottom: 60px;
}
.ryoshinobukkakenori-japanfood--list ul {
  margin-bottom: 40px;
}
.ryoshinobukkakenori-japanfood--list a {
  word-break: break-all;
}
